import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import KEYCODE from './KeyCode';

var Options = function (_React$Component) {
  _inherits(Options, _React$Component);

  function Options(props) {
    _classCallCheck(this, Options);

    var _this = _possibleConstructorReturn(this, (Options.__proto__ || Object.getPrototypeOf(Options)).call(this, props));

    _this.buildOptionText = function (value) {
      return value + ' ' + _this.props.locale.items_per_page;
    };

    _this.changeSize = function (value) {
      _this.props.changeSize(Number(value));
    };

    _this.handleChange = function (e) {
      _this.setState({
        goInputText: e.target.value
      });
    };

    _this.go = function (e) {
      var val = _this.state.goInputText;
      if (val === '') {
        return;
      }
      val = isNaN(val) ? _this.props.current : Number(val);
      if (e.keyCode === KEYCODE.ENTER || e.type === 'click') {
        _this.setState({
          goInputText: ''
        });
        _this.props.quickGo(val);
      }
    };

    _this.state = {
      goInputText: ''
    };
    return _this;
  }

  _createClass(Options, [{
    key: 'render',
    value: function render() {
      var props = this.props;
      var state = this.state;
      var locale = props.locale;
      var prefixCls = props.rootPrefixCls + '-options';
      var changeSize = props.changeSize;
      var quickGo = props.quickGo;
      var goButton = props.goButton;
      var buildOptionText = props.buildOptionText || this.buildOptionText;
      var Select = props.selectComponentClass;
      var changeSelect = null;
      var goInput = null;
      var gotoButton = null;

      if (!(changeSize || quickGo)) {
        return null;
      }

      if (changeSize && Select) {
        var Option = Select.Option;
        var pageSize = props.pageSize || props.pageSizeOptions[0];
        var options = props.pageSizeOptions.map(function (opt, i) {
          return React.createElement(
            Option,
            { key: i, value: opt },
            buildOptionText(opt)
          );
        });

        changeSelect = React.createElement(
          Select,
          {
            prefixCls: props.selectPrefixCls,
            showSearch: false,
            className: prefixCls + '-size-changer',
            optionLabelProp: 'children',
            dropdownMatchSelectWidth: false,
            value: pageSize.toString(),
            onChange: this.changeSize,
            getPopupContainer: function getPopupContainer(triggerNode) {
              return triggerNode.parentNode;
            }
          },
          options
        );
      }

      if (quickGo) {
        if (goButton) {
          if (typeof goButton === 'boolean') {
            gotoButton = React.createElement(
              'button',
              {
                type: 'button',
                onClick: this.go,
                onKeyUp: this.go
              },
              locale.jump_to_confirm
            );
          } else {
            gotoButton = React.createElement(
              'span',
              {
                onClick: this.go,
                onKeyUp: this.go
              },
              goButton
            );
          }
        }
        goInput = React.createElement(
          'div',
          { className: prefixCls + '-quick-jumper' },
          locale.jump_to,
          React.createElement('input', {
            type: 'text',
            value: state.goInputText,
            onChange: this.handleChange,
            onKeyUp: this.go
          }),
          locale.page,
          gotoButton
        );
      }

      return React.createElement(
        'li',
        { className: '' + prefixCls },
        changeSelect,
        goInput
      );
    }
  }]);

  return Options;
}(React.Component);

Options.propTypes = {
  changeSize: PropTypes.func,
  quickGo: PropTypes.func,
  selectComponentClass: PropTypes.func,
  current: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  pageSize: PropTypes.number,
  buildOptionText: PropTypes.func,
  locale: PropTypes.object
};
Options.defaultProps = {
  pageSizeOptions: ['10', '20', '30', '40']
};


export default Options;