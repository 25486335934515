import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@tmp/history';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/index.js'),
        })
      : require('../../layouts/index.js').default,
    routes: [
      {
        path: '/404',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../404.js'),
            })
          : require('../404.js').default,
      },
      {
        path: '/addpass',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../addpass/index.js'),
            })
          : require('../addpass/index.js').default,
      },
      {
        path: '/demo/helper',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../demo/helper.js'),
            })
          : require('../demo/helper.js').default,
      },
      {
        path: '/demo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../demo/index.js'),
            })
          : require('../demo/index.js').default,
      },
      {
        path: '/enterprise/add',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/add/index.js'),
            })
          : require('../enterprise/add/index.js').default,
      },
      {
        path: '/enterprise/component/card',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/component/card/index.js'),
            })
          : require('../enterprise/component/card/index.js').default,
      },
      {
        path: '/enterprise/component/multiTree',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../enterprise/component/multiTree/index.js'),
            })
          : require('../enterprise/component/multiTree/index.js').default,
      },
      {
        path: '/enterprise/component/tree',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/component/tree/index.js'),
            })
          : require('../enterprise/component/tree/index.js').default,
      },
      {
        path: '/enterprise/detail/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/detail/$id.js'),
            })
          : require('../enterprise/detail/$id.js').default,
      },
      {
        path: '/enterprise/edit/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/edit/$id.js'),
            })
          : require('../enterprise/edit/$id.js').default,
      },
      {
        path: '/enterprise',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/index.js'),
            })
          : require('../enterprise/index.js').default,
      },
      {
        path: '/enterprise/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/list/index.js'),
            })
          : require('../enterprise/list/index.js').default,
      },
      {
        path: '/enterprise/right/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/right/$id.js'),
            })
          : require('../enterprise/right/$id.js').default,
      },
      {
        path: '/enterprise/account',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                  m => {
                    return { namespace: 'account', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                  m => {
                    return { namespace: 'pEnterpriseInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                  m => {
                    return { namespace: 'pEnterpriseList', ...m.default };
                  },
                ),
              ],
              component: () => import('../enterprise/account/_layout.js'),
            })
          : require('../enterprise/account/_layout.js').default,
        routes: [
          {
            path: '/enterprise/account/a/add',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../enterprise/account/a/add.js'),
                })
              : require('../enterprise/account/a/add.js').default,
          },
          {
            path: '/enterprise/account/a/detail',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../enterprise/account/a/detail.js'),
                })
              : require('../enterprise/account/a/detail.js').default,
          },
          {
            path: '/enterprise/account/a/edit',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../enterprise/account/a/edit.js'),
                })
              : require('../enterprise/account/a/edit.js').default,
          },
          {
            path: '/enterprise/account/a',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../enterprise/account/a/index.js'),
                })
              : require('../enterprise/account/a/index.js').default,
          },
          {
            path: '/enterprise/account/a/tableList',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../enterprise/account/a/tableList.js'),
                })
              : require('../enterprise/account/a/tableList.js').default,
          },
          {
            path: '/enterprise/account/role',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../enterprise/account/role/index.js'),
                })
              : require('../enterprise/account/role/index.js').default,
          },
          {
            path: '/enterprise/account/role/tableList',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../enterprise/account/role/tableList.js'),
                })
              : require('../enterprise/account/role/tableList.js').default,
          },
          {
            path: '/enterprise/account/roleDetails',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../enterprise/account/roleDetails/index.js'),
                })
              : require('../enterprise/account/roleDetails/index.js').default,
          },
          {
            path: '/enterprise/account/roleEdit',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/account.js').then(
                      m => {
                        return { namespace: 'account', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/account/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseInfo.js').then(
                      m => {
                        return { namespace: 'pEnterpriseInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/enterprise/models/pEnterpriseList.js').then(
                      m => {
                        return { namespace: 'pEnterpriseList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../enterprise/account/roleEdit/index.js'),
                })
              : require('../enterprise/account/roleEdit/index.js').default,
          },
        ],
      },
      {
        path: '/forgotpass',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../forgotpass/index.js'),
            })
          : require('../forgotpass/index.js').default,
      },
      {
        path: '/',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../index.js'),
            })
          : require('../index.js').default,
      },
      {
        path: '/login',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../login/index.js'),
            })
          : require('../login/index.js').default,
      },
      {
        path: '/station',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                  m => {
                    return { namespace: 'pStationDic', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                  m => {
                    return { namespace: 'pStationEnergyDevice', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                  m => {
                    return { namespace: 'pStationInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                  m => {
                    return { namespace: 'pStationInfoGraph', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                  m => {
                    return {
                      namespace: 'pStationInfoMeasurement',
                      ...m.default,
                    };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                  m => {
                    return { namespace: 'pStationInfoMetric', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                  m => {
                    return { namespace: 'pStationList', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                  m => {
                    return { namespace: 'pStationStatement', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                  m => {
                    return { namespace: 'pStationTransform', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                  m => {
                    return { namespace: 'pStationVirtualDevice', ...m.default };
                  },
                ),
              ],
              component: () => import('../station/index.js'),
            })
          : require('../station/index.js').default,
      },
      {
        path: '/station/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                  m => {
                    return { namespace: 'pStationDic', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                  m => {
                    return { namespace: 'pStationEnergyDevice', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                  m => {
                    return { namespace: 'pStationInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                  m => {
                    return { namespace: 'pStationInfoGraph', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                  m => {
                    return {
                      namespace: 'pStationInfoMeasurement',
                      ...m.default,
                    };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                  m => {
                    return { namespace: 'pStationInfoMetric', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                  m => {
                    return { namespace: 'pStationList', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                  m => {
                    return { namespace: 'pStationStatement', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                  m => {
                    return { namespace: 'pStationTransform', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                  m => {
                    return { namespace: 'pStationVirtualDevice', ...m.default };
                  },
                ),
              ],
              component: () => import('../station/list/index.js'),
            })
          : require('../station/list/index.js').default,
      },
      {
        path: '/station/info',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                  m => {
                    return { namespace: 'pStationDic', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                  m => {
                    return { namespace: 'pStationEnergyDevice', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                  m => {
                    return { namespace: 'pStationInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                  m => {
                    return { namespace: 'pStationInfoGraph', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                  m => {
                    return {
                      namespace: 'pStationInfoMeasurement',
                      ...m.default,
                    };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                  m => {
                    return { namespace: 'pStationInfoMetric', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                  m => {
                    return { namespace: 'pStationList', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                  m => {
                    return { namespace: 'pStationStatement', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                  m => {
                    return { namespace: 'pStationTransform', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                  m => {
                    return { namespace: 'pStationVirtualDevice', ...m.default };
                  },
                ),
              ],
              component: () => import('../station/info/_layout.js'),
            })
          : require('../station/info/_layout.js').default,
        routes: [
          {
            path: '/station/info/communication',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/communication/index.js'),
                })
              : require('../station/info/communication/index.js').default,
          },
          {
            path: '/station/info/dataImport',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/dataImport/index.js'),
                })
              : require('../station/info/dataImport/index.js').default,
          },
          {
            path: '/station/info/detail',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () => import('../station/info/detail/index.js'),
                })
              : require('../station/info/detail/index.js').default,
          },
          {
            path: '/station/info/device',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () => import('../station/info/device/index.js'),
                })
              : require('../station/info/device/index.js').default,
          },
          {
            path: '/station/info/edit',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () => import('../station/info/edit/index.js'),
                })
              : require('../station/info/edit/index.js').default,
          },
          {
            path: '/station/info/graph',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () => import('../station/info/graph/index.js'),
                })
              : require('../station/info/graph/index.js').default,
          },
          {
            path: '/station/info/graph/list',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () => import('../station/info/graph/list.js'),
                })
              : require('../station/info/graph/list.js').default,
          },
          {
            path: '/station/info/graph/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () => import('../station/info/graph/$id.js'),
                })
              : require('../station/info/graph/$id.js').default,
          },
          {
            path: '/station/info',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () => import('../station/info/index.js'),
                })
              : require('../station/info/index.js').default,
          },
          {
            path: '/station/info/measurement',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/measurement/index.js'),
                })
              : require('../station/info/measurement/index.js').default,
          },
          {
            path: '/station/info/metric',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () => import('../station/info/metric/index.js'),
                })
              : require('../station/info/metric/index.js').default,
          },
          {
            path: '/station/info/virtualDevice/constant',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/virtualDevice/constant.js'),
                })
              : require('../station/info/virtualDevice/constant.js').default,
          },
          {
            path: '/station/info/virtualDevice/detail/:typedId',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/virtualDevice/detail/$typedId.js'),
                })
              : require('../station/info/virtualDevice/detail/$typedId.js')
                  .default,
          },
          {
            path: '/station/info/virtualDevice/device.config',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/virtualDevice/device.config.js'),
                })
              : require('../station/info/virtualDevice/device.config.js')
                  .default,
          },
          {
            path: '/station/info/virtualDevice/edit/:typedId',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/virtualDevice/edit/$typedId.js'),
                })
              : require('../station/info/virtualDevice/edit/$typedId.js')
                  .default,
          },
          {
            path: '/station/info/virtualDevice',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/virtualDevice/index.js'),
                })
              : require('../station/info/virtualDevice/index.js').default,
          },
          {
            path: '/station/info/importantDevice',
            exact: false,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/importantDevice/_layout.js'),
                })
              : require('../station/info/importantDevice/_layout.js').default,
            routes: [
              {
                path:
                  '/station/info/importantDevice/energyDevice/detail/:typedId',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/importantDevice/energyDevice/detail/$typedId.js'),
                    })
                  : require('../station/info/importantDevice/energyDevice/detail/$typedId.js')
                      .default,
              },
              {
                path:
                  '/station/info/importantDevice/energyDevice/device.config',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/importantDevice/energyDevice/device.config.js'),
                    })
                  : require('../station/info/importantDevice/energyDevice/device.config.js')
                      .default,
              },
              {
                path:
                  '/station/info/importantDevice/energyDevice/edit/:typedId',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/importantDevice/energyDevice/edit/$typedId.js'),
                    })
                  : require('../station/info/importantDevice/energyDevice/edit/$typedId.js')
                      .default,
              },
              {
                path: '/station/info/importantDevice/energyDevice',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/importantDevice/energyDevice/index.js'),
                    })
                  : require('../station/info/importantDevice/energyDevice/index.js')
                      .default,
              },
              {
                path: '/station/info/importantDevice',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/importantDevice/index.js'),
                    })
                  : require('../station/info/importantDevice/index.js').default,
              },
              {
                path: '/station/info/importantDevice/transform/detail/:id',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/importantDevice/transform/detail/$id.js'),
                    })
                  : require('../station/info/importantDevice/transform/detail/$id.js')
                      .default,
              },
              {
                path: '/station/info/importantDevice/transform/edit/:id',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/importantDevice/transform/edit/$id.js'),
                    })
                  : require('../station/info/importantDevice/transform/edit/$id.js')
                      .default,
              },
              {
                path: '/station/info/importantDevice/transform',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/importantDevice/transform/index.js'),
                    })
                  : require('../station/info/importantDevice/transform/index.js')
                      .default,
              },
            ],
          },
          {
            path: '/station/info/statement',
            exact: false,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                      m => {
                        return { namespace: 'pStationDic', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationEnergyDevice',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                      m => {
                        return { namespace: 'pStationInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                      m => {
                        return { namespace: 'pStationInfoGraph', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMeasurement',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                      m => {
                        return {
                          namespace: 'pStationInfoMetric',
                          ...m.default,
                        };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                      m => {
                        return { namespace: 'pStationList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                      m => {
                        return { namespace: 'pStationStatement', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                      m => {
                        return { namespace: 'pStationTransform', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                      m => {
                        return {
                          namespace: 'pStationVirtualDevice',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../station/info/statement/_layout.js'),
                })
              : require('../station/info/statement/_layout.js').default,
            routes: [
              {
                path: '/station/info/statement/detail/:id',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/statement/detail/$id.js'),
                    })
                  : require('../station/info/statement/detail/$id.js').default,
              },
              {
                path: '/station/info/statement/edit',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/statement/edit/index.js'),
                    })
                  : require('../station/info/statement/edit/index.js').default,
              },
              {
                path: '/station/info/statement/edit/:id',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/statement/edit/$id.js'),
                    })
                  : require('../station/info/statement/edit/$id.js').default,
              },
              {
                path: '/station/info/statement',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/statement/index.js'),
                    })
                  : require('../station/info/statement/index.js').default,
              },
              {
                path: '/station/info/statement/list',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationDic.js').then(
                          m => {
                            return { namespace: 'pStationDic', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationEnergyDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationEnergyDevice',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfo.js').then(
                          m => {
                            return { namespace: 'pStationInfo', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoGraph.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoGraph',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMeasurement.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMeasurement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationInfoMetric.js').then(
                          m => {
                            return {
                              namespace: 'pStationInfoMetric',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationList.js').then(
                          m => {
                            return { namespace: 'pStationList', ...m.default };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationStatement.js').then(
                          m => {
                            return {
                              namespace: 'pStationStatement',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationTransform.js').then(
                          m => {
                            return {
                              namespace: 'pStationTransform',
                              ...m.default,
                            };
                          },
                        ),
                        import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/station/models/pStationVirtualDevice.js').then(
                          m => {
                            return {
                              namespace: 'pStationVirtualDevice',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import('../station/info/statement/list/index.js'),
                    })
                  : require('../station/info/statement/list/index.js').default,
              },
            ],
          },
        ],
      },
      {
        path: '/usercenter/component/baseInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../usercenter/component/baseInfo/index.js'),
            })
          : require('../usercenter/component/baseInfo/index.js').default,
      },
      {
        path: '/usercenter/component/card',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../usercenter/component/card/index.js'),
            })
          : require('../usercenter/component/card/index.js').default,
      },
      {
        path: '/usercenter/component/safetyInfo/email',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../usercenter/component/safetyInfo/email.js'),
            })
          : require('../usercenter/component/safetyInfo/email.js').default,
      },
      {
        path: '/usercenter/component/safetyInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../usercenter/component/safetyInfo/index.js'),
            })
          : require('../usercenter/component/safetyInfo/index.js').default,
      },
      {
        path: '/usercenter/component/safetyInfo/mobile',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../usercenter/component/safetyInfo/mobile.js'),
            })
          : require('../usercenter/component/safetyInfo/mobile.js').default,
      },
      {
        path: '/usercenter/component/safetyInfo/password',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../usercenter/component/safetyInfo/password.js'),
            })
          : require('../usercenter/component/safetyInfo/password.js').default,
      },
      {
        path: '/usercenter/component/safetyLevel',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../usercenter/component/safetyLevel/index.js'),
            })
          : require('../usercenter/component/safetyLevel/index.js').default,
      },
      {
        path: '/usercenter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../usercenter/index.js'),
            })
          : require('../usercenter/index.js').default,
      },
      {
        path: '/usercenter/info',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../usercenter/info.js'),
            })
          : require('../usercenter/info.js').default,
      },
      {
        path: '/usercenter/tool',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/usercenter/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../usercenter/tool.js'),
            })
          : require('../usercenter/tool.js').default,
      },
      {
        path: '/energy-tag',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/energy-tag/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../energy-tag/_layout.js'),
            })
          : require('../energy-tag/_layout.js').default,
        routes: [
          {
            path: '/energy-tag',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/energy-tag/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../energy-tag/index.js'),
                })
              : require('../energy-tag/index.js').default,
          },
          {
            path: '/energy-tag/list',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/energy-tag/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../energy-tag/list/index.js'),
                })
              : require('../energy-tag/list/index.js').default,
          },
        ],
      },
      {
        path: '/rightsmanagement',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
              ],
              component: () => import('../rightsmanagement/_layout.js'),
            })
          : require('../rightsmanagement/_layout.js').default,
        routes: [
          {
            path: '/rightsmanagement/account/add',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../rightsmanagement/account/add.js'),
                })
              : require('../rightsmanagement/account/add.js').default,
          },
          {
            path: '/rightsmanagement/account/detail',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/account/detail.js'),
                })
              : require('../rightsmanagement/account/detail.js').default,
          },
          {
            path: '/rightsmanagement/account/edit',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/account/edit.js'),
                })
              : require('../rightsmanagement/account/edit.js').default,
          },
          {
            path: '/rightsmanagement/account',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/account/index.js'),
                })
              : require('../rightsmanagement/account/index.js').default,
          },
          {
            path: '/rightsmanagement/account/tableList',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/account/tableList.js'),
                })
              : require('../rightsmanagement/account/tableList.js').default,
          },
          {
            path: '/rightsmanagement/component/card',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/component/card/index.js'),
                })
              : require('../rightsmanagement/component/card/index.js').default,
          },
          {
            path: '/rightsmanagement/component/tree',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/component/tree/index.js'),
                })
              : require('../rightsmanagement/component/tree/index.js').default,
          },
          {
            path: '/rightsmanagement',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../rightsmanagement/index.js'),
                })
              : require('../rightsmanagement/index.js').default,
          },
          {
            path: '/rightsmanagement/role',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../rightsmanagement/role/index.js'),
                })
              : require('../rightsmanagement/role/index.js').default,
          },
          {
            path: '/rightsmanagement/role/tableList',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/role/tableList.js'),
                })
              : require('../rightsmanagement/role/tableList.js').default,
          },
          {
            path: '/rightsmanagement/roleDetails',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/roleDetails/index.js'),
                })
              : require('../rightsmanagement/roleDetails/index.js').default,
          },
          {
            path: '/rightsmanagement/roleEdit',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/accountList.js').then(
                      m => {
                        return { namespace: 'accountList', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleInfo.js').then(
                      m => {
                        return { namespace: 'roleInfo', ...m.default };
                      },
                    ),
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/rightsmanagement/models/roleList.js').then(
                      m => {
                        return { namespace: 'roleList', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../rightsmanagement/roleEdit/index.js'),
                })
              : require('../rightsmanagement/roleEdit/index.js').default,
          },
        ],
      },
      {
        path: '/transformer-examine',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/transformer-examine/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../transformer-examine/_layout.js'),
            })
          : require('../transformer-examine/_layout.js').default,
        routes: [
          {
            '0': 'l',
            '1': 'e',
            '2': 'v',
            '3': 'e',
            '4': 'l',
            '5': ' ',
            '6': '0',
            '7': ':',
            '8': '优',
            '9': ' ',
            '10': '1',
            '11': ':',
            '12': '良',
            '13': ' ',
            '14': '2',
            '15': ':',
            '16': '一',
            '17': '般',
            '18': ' ',
            '19': '3',
            '20': ':',
            '21': '差',
            path: '/transformer-examine/detail/parseDesc',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/transformer-examine/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../transformer-examine/detail/parseDesc.js'),
                })
              : require('../transformer-examine/detail/parseDesc.js').default,
          },
          {
            path: '/transformer-examine/detail/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/transformer-examine/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../transformer-examine/detail/$id.js'),
                })
              : require('../transformer-examine/detail/$id.js').default,
          },
          {
            path: '/transformer-examine/history/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/transformer-examine/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../transformer-examine/history/$id.js'),
                })
              : require('../transformer-examine/history/$id.js').default,
          },
          {
            path: '/transformer-examine',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/transformer-examine/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../transformer-examine/index.js'),
                })
              : require('../transformer-examine/index.js').default,
          },
          {
            path: '/transformer-examine/list',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/pages/transformer-examine/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../transformer-examine/list/index.js'),
                })
              : require('../transformer-examine/list/index.js').default,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../404.js'),
            })
          : require('../404.js').default,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen = () => {};

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    routeChangeHandler(history.location);
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return <Router history={history}>{renderRoutes(routes, props)}</Router>;
  }
}
