import Fingerprint2 from 'fingerprintjs2';
import * as storage from './storage';

const KEY = 'fingerprintjs2_value';

async function set() {
  const v = await new Promise((res) => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => {
        Fingerprint2.get((components) => {
          res(Fingerprint2.x64hash128(components.map(i => i?.value).join(''), 31));
        });
      });
    } else {
      setTimeout(() => {
        Fingerprint2.get((components) => {
          res(Fingerprint2.x64hash128(components.map(i => i?.value).join(''), 31));
        });
      }, 500);
    }
  });

  storage.set(KEY, v);

  return v;
}

export async function get() {
  const v = storage.get(KEY) || await set();
  return v;
}
