import React from 'react';

import styles from './Filter.scss';

function Filter(props) {
  const { label, children, className, style } = props;
  return (
    <div className={[styles.filter, className]} style={style}>
      {label ? <div className={styles.label}>{label}</div> : null}
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default Filter;
