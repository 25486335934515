import React from 'react';
import { composeClass } from '@utils/tool';
import { Radio } from 'antd';
import RadioButton from './RadioButton';
import styles from './Radio.scss';


class RadioComponent extends React.Component {
  static RadioGroup = Radio.Group;

  static Button = RadioButton;

  render() {
    const { className, ...restProps } = this.props;
    const classString = composeClass(styles.ui, restProps.children ? styles.addHoverBackground : '', className);
    return (
      <Radio className={classString} {...restProps} />
    );
  }
}
export default RadioComponent;
