import React from 'react';
import { Switch as AntdSwtich } from 'antd';

import styles from './index.scss';
import { multiClass } from '../tool';

export default function Switch(props) {
  return (
    <AntdSwtich
      checkedChildren="开"
      unCheckedChildren="关"
      {...props}
      className={multiClass(styles.ui, props.className)}
    />
  );
}
