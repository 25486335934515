import React from 'react';
import ReactDOM from 'react-dom';
import Confirm from './Confirm';

const instances = {};

function newInstance(properties) {
  return new Promise((resolve) => {
    const { getContainer, ...props } = properties || {};
    const div = document.createElement('div');
    if (getContainer) {
      const root = getContainer();
      root.appendChild(div);
    } else {
      document.body.appendChild(div);
    }
    let called = false;
    function ref(confirmIns) {
      if (called) {
        return;
      }
      called = true;
      resolve({
        async typedModal(type, ...insProps) {
          return await confirmIns.typedModal(type)(...insProps);
        },
        component: confirmIns,
        destroy() {
          ReactDOM.unmountComponentAtNode(div);
          div.parentNode.removeChild(div);
        },
      });
    }
    ReactDOM.render(<Confirm {...props} ref={ref} />, div);
  });
}

function getInstance(key) {
  return new Promise(async (resolve) => {
    const existedIns = instances[key];
    if (existedIns) {
      resolve(existedIns);
      return;
    }
    const ins = await newInstance();
    resolve(ins);
    instances[key] = ins;
  });
}

export default {
  async confirm(...args) {
    const ins = await getInstance('globalOnlyConfirmKey');
    return await ins.typedModal('confirm', ...args);
  },
  async info(...args) {
    const ins = await getInstance('globalOnlyConfirmKey');
    return await ins.typedModal('info', ...args);
  },
  async error(...args) {
    const ins = await getInstance('globalOnlyConfirmKey');
    return await ins.typedModal('error', ...args);
  },
};
