import React from 'react';
import { Checkbox } from 'antd';
import { multiClass } from '../tool';
import styles from './Checkbox.scss';


class CheckboxComponent extends React.Component {
  render() {
    const { className, icon, ...restProps } = this.props;
    const classString = multiClass(
      restProps.disabled ? 'ant-checkbox-wrapper-disabled' : '',
      styles.checkbox,
      restProps.children ? styles.addHoverBackground : '',
      icon === 'square' && styles.square,
      className);
    return (
      <Checkbox className={classString} {...restProps} />
    );
  }
}
export default CheckboxComponent;
