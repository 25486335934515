import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'address', ...(require('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/models/address.js').default) });
app.model({ namespace: 'base', ...(require('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/models/base.js').default) });
app.model({ namespace: 'users', ...(require('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/models/bizlog/users.js').default) });
app.model({ namespace: 'dict', ...(require('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/models/dict.js').default) });
app.model({ namespace: 'environment', ...(require('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/models/environment.js').default) });
app.model({ namespace: 'right', ...(require('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/models/right.js').default) });
app.model({ namespace: 'upload', ...(require('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/models/upload.js').default) });
app.model({ namespace: 'user', ...(require('/root/.jenkins/workspace/web-saas.manager.smarteoc.com-new/src/models/user.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
