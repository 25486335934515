import mapCodeToMsg from '@utils/resCodeHash';
import request from '@utils/request';
import { isObject, identity, isNil } from 'lodash';
import * as store from './storage';
import * as fingerprint from './fingerprint';

let lastTime = null;

function ErrorRes(code, message = '') {
  this.requestId = null;
  this.type = 'ClientError';
  this.code = code;
  this.message = message;
}

const fixAsOpenApiParam = (maybeP) => {
  if (!isObject(maybeP)) return {};
  return Object.entries(maybeP).reduce((map, [key, value]) => {
    if (isObject(value)) return { ...map, [key]: JSON.stringify(value) };
    return { ...map, [key]: value };
  }, {});
};

export const requestData = async (method, datas) => {
  try {
    const fingerprintValue = await fingerprint.get();
    const data = await request(method, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.get('token')}`,
        'client-agent': `PC;${fingerprintValue}`,
      },
      body: datas,
    });
    lastTime = moment();
    return data;
  } catch (ex) {
    console.log(ex);
    return new ErrorRes('fetch error');
  }
};

export async function getData(service, action, params = {}, version = 'latest') {
  const datas = JSON.stringify({ ...params, platformType: 'm0' });

  const resData = await requestData(`/api/${version}/${service}/${action}`, datas);

  // message重写
  resData.srcMsg = resData.msg;
  resData.srcMessage = resData.message;

  if (resData.code) resData.message = mapCodeToMsg(resData.code, resData.srcMessage);

  return resData;
}

export async function getGeneralData(path, params = {}) {
  const datas = JSON.stringify({
    datas: params,
  });
  return await requestData(path, datas);
}

export function getDataParti(service, action, transfomer = identity, ...args) {
  return async function _getDataParti(...params) {
    return await getData(service, action, transfomer(...params), ...args);
  };
}

export function getExpectedData(type) {
  let expected = null;
  switch (type) {
    case 'boolean':
      expected = false;
      break;
    case 'string':
      expected = '';
      break;
    case 'object':
      expected = {};
      break;
    case 'array':
    case 'page':
      expected = [];
      break;
    case 'number':
      expected = 0;
      break;
    default:
      expected = null;
  }

  const typesList = ['array', 'page'];

  return (service, action, transfomerArgs = identity, transfomerExpected = identity) => async (args) => {
    let e = _.clone(expected);
    const argsTransformed = transfomerArgs(args);
    const res = await getData(service, action, argsTransformed);

    if (res.code === null) {
      e = type === 'boolean' ?
        true :
        (typesList.some(i => type === i) ? res.dataList : res.data) ?? e;
    }
    e = transfomerExpected(e, res, args, argsTransformed);

    return [e, res];
  };
}

export const getDataBoolean = getExpectedData('boolean');

export const getDataString = getExpectedData('string');

export const getDataObject = getExpectedData('object');

export const getDataArray = getExpectedData('array');

export const getDataPage = getExpectedData('page');

export const getDataNumber = getExpectedData('number');

setInterval(async () => {
  const token = store.get('token');
  if (token && token.length > 0) {
    if (lastTime && lastTime.add(5, 'minutes').isAfter(moment())) {
      const res = await getData('user', 'heartBeat', {});
      if (res.code) console.log('heartBeat error', res);
    }
  }
}, 5 * 60 * 1000);
