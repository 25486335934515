import React from 'react';
import { composeClass } from '@utils/tool';
import styles from './Text.scss';

class FormText extends React.Component {
  state = {
    value: this.props.value,
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    this.setState({ value });
  }

  render() {
    const { className = '', style = {} } = this.props;
    return (<div className={composeClass(styles.text, className)} style={style}>{this.state.value}</div>);
  }
}

export default FormText;
