import { getData } from '@services/request';


/**
 * 获取 省份列表
 */
export async function queryAllProvince() {
  let fres = {};

  const data = await getData('address', 'queryAllProvince');

  if (!data.code) {
    try {
      fres = data.dataList.reduce((acc, item) => {
        acc[item.code] = item.name;
        return acc;
      }, {});
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}

/**
 * 获取 地区列表
 */
export async function querySubArea({ code: parentCode, type: parentType }) {
  let fres = {};

  const data = await getData('address', 'querySubArea', {
    parentCode,
    parentType,
  });

  if (!data.code) {
    try {
      fres = data.dataList.reduce((acc, item) => {
        acc[item.code] = item.name;
        return acc;
      }, {});
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}
