import React from 'react';
import { TimePicker } from 'antd';
import styles from './TimePicker.scss';
import { multiClass } from '../tool';

class CTimePicker extends React.Component {

  render() {
    const { className, ...restProps } = this.props;
    return (
      <TimePicker
        className={multiClass(styles['time-picker'], className)}
        {...restProps}
      />
    );
  }
}
export default CTimePicker;
