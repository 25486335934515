
import moment from 'moment';
import 'moment/locale/zh-cn';

import 'normalize.css';
import 'font-awesome/less/font-awesome.less';
import './index.scss';

moment.locale('zh-cn');

export const dva = {
  config: {
    onError(err) {
      err.preventDefault();
      console.error(err.message);
    },
  },
};
