import router from 'umi/router';
import * as services from '@services/user';
import * as servicesAccount from '@openapi/account';
import * as storage from '@services/storage';

export default {
  state: {
    data: {},
    currentUser: '',
  },

  reducers: {
    clearUpdate(_, { payload }) {
      return { ...payload };
    },
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetch(_, { put, call, take }) {
      const user = storage.get('user');  // 最差的情况是仅有 login 返回的字段
      const token = storage.get('token');

      if (typeof user === 'object' && user && Object.keys(user).length > 0 && token) {
        const { accountId: id } = user;

        // 获取更详细的用户信息
        const [userInfo] = yield call(services.queryUserInfo, { id });
        const data = {
          ...user,
          ...userInfo,
          currentUser: id,
        };

        // 超管帐号及其下属帐号会有所属企业，没有则认为不合法
        const [enterprises] = yield call(servicesAccount.queryEnterpriseByAccountId, { accountId: user.accountId });
        const isValid = enterprises.length > 0;

        if (!isValid) {
          router.push('/login');
          return;
        }

        yield put({ type: 'right/fetchAccess', payload: { accountId: user.accountId, enterpriseId: enterprises?.[0]?.id } });
        yield take('right/fetchAccess/@@end');

        // console.log(data);
        yield put({
          type: 'update',
          payload: {
            ...data,
            data,
          },
        });

        yield put({
          type: 'base/update',
          payload: {
            user: data,
            currentUser: id,
            currentEnterpriseId: enterprises?.[0]?.id,
          },
        });

        // 重新注入 storage hash，获取更好的性能
        storage.set('user', data);
        storage.set('token', token);
      } else {
        router.push('/login');
      }
    },

    checkLogin(_, { put }) {
      const user = storage.get('user');
      const token = storage.get('token');

      if (!(typeof user === 'object' && Object.keys(user).length > 0 && token)) {
        router.push('/login');
      }
    },

    *login({ payload: { data: user = {} } }, { put, call }) {
      const { customerId: id, token } = user;

      if (Object.keys(user).length) {
        const enterprises = user.enterpriseList;

        const isValid = enterprises.length > 0;

        if (!isValid) {
          return { code: 'invalid' };
        }

        yield put({
          type: 'update',
          payload: {
            ...user,
            data: user,
            currentUser: id,
          },
        });

        yield put({
          type: 'base/update',
          payload: {
            user,
            currentUser: id,
            currentEnterpriseId: enterprises?.[0]?.id,
          },
        });

        storage.set('user', user);
        storage.set('token', token);

        if (!user.hasPassword) {
          // 使用验证码登录且没有密码，跳转至增加密码
          router.push('/addpass');
          return;
        }

        yield put({
          type: 'bizlogUser/createItemLogin',
        });

        yield put({ type: 'base/reLocate' });
      } else {
        // 处理登录错误
        // return data;
      }
    },

    *logout(_, { put }) {
      // 清空 token
      storage.remove('token');

      // 清空 user
      storage.remove('user');
      yield put({
        type: 'clearUpdate',
        payload: {
          data: {},
          currentUser: '',
        },
      });

      // 清空 base 中相关信息
      yield put({
        type: 'base/update',
        payload: {
          user: {},
          currentUser: '',
        },
      });

      yield put({
        type: 'right/clean',
      });

      router.push('/login');
    },
  },

  subscriptions: {
  },
};
