import React from 'react';
import { Radio } from 'antd';
import styles from './Radio.scss';
import { multiClass } from '../tool';

const AntdRadioButton = Radio.Button;

class RadioButton extends React.Component {
  render() {
    const { tight = false, className, ...resProps } = this.props;
    return <AntdRadioButton className={multiClass(styles['radio-button'], tight ? styles.tight : '', className)} {...resProps} />;
  }
}

export default RadioButton;
