import _objectDestructuringEmpty from 'babel-runtime/helpers/objectDestructuringEmpty';
import _extends from 'babel-runtime/helpers/extends';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import warning from 'warning';
import { traverseTreeNodes, getStrictlyValue, getFullKeyList, getPosition, getDragNodesKeys, calcExpandedKeys, calcSelectedKeys, calcCheckedKeys, calcDropPosition, arrAdd, arrDel, posToArr } from './util';

/**
 * Thought we still use `cloneElement` to pass `key`,
 * other props can pass with context for future refactor.
 */
export var contextTypes = {
  rcTree: PropTypes.shape({
    root: PropTypes.object,

    prefixCls: PropTypes.string,
    selectable: PropTypes.bool,
    showIcon: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    draggable: PropTypes.bool,
    checkable: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    checkStrictly: PropTypes.bool,
    disabled: PropTypes.bool,
    openTransitionName: PropTypes.string,
    openAnimation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    loadData: PropTypes.func,
    filterTreeNode: PropTypes.func,
    renderTreeNode: PropTypes.func,

    isKeyChecked: PropTypes.func,

    onNodeExpand: PropTypes.func,
    onNodeSelect: PropTypes.func,
    onNodeMouseEnter: PropTypes.func,
    onNodeMouseLeave: PropTypes.func,
    onNodeContextMenu: PropTypes.func,
    onNodeDragStart: PropTypes.func,
    onNodeDragEnter: PropTypes.func,
    onNodeDragOver: PropTypes.func,
    onNodeDragLeave: PropTypes.func,
    onNodeDragEnd: PropTypes.func,
    onNodeDrop: PropTypes.func,
    onBatchNodeCheck: PropTypes.func,
    onCheckConductFinished: PropTypes.func
  })
};

var Tree = function (_React$Component) {
  _inherits(Tree, _React$Component);

  function Tree(props) {
    _classCallCheck(this, Tree);

    var _this = _possibleConstructorReturn(this, _React$Component.call(this, props));

    _initialiseProps.call(_this);

    var defaultExpandAll = props.defaultExpandAll,
        defaultExpandParent = props.defaultExpandParent,
        defaultExpandedKeys = props.defaultExpandedKeys,
        defaultCheckedKeys = props.defaultCheckedKeys,
        defaultSelectedKeys = props.defaultSelectedKeys,
        expandedKeys = props.expandedKeys;

    // Sync state with props

    var _ref = calcCheckedKeys(defaultCheckedKeys, props) || {},
        _ref$checkedKeys = _ref.checkedKeys,
        checkedKeys = _ref$checkedKeys === undefined ? [] : _ref$checkedKeys,
        _ref$halfCheckedKeys = _ref.halfCheckedKeys,
        halfCheckedKeys = _ref$halfCheckedKeys === undefined ? [] : _ref$halfCheckedKeys;

    var state = {
      selectedKeys: calcSelectedKeys(defaultSelectedKeys, props),
      checkedKeys: checkedKeys,
      halfCheckedKeys: halfCheckedKeys
    };

    if (defaultExpandAll) {
      state.expandedKeys = getFullKeyList(props.children);
    } else if (defaultExpandParent) {
      state.expandedKeys = calcExpandedKeys(expandedKeys || defaultExpandedKeys, props);
    } else {
      state.expandedKeys = defaultExpandedKeys;
    }

    _this.state = _extends({}, state, _this.getSyncProps(props) || {});

    // Cache for check status to optimize
    _this.checkedBatch = null;
    return _this;
  }

  Tree.prototype.getChildContext = function getChildContext() {
    var _props = this.props,
        prefixCls = _props.prefixCls,
        selectable = _props.selectable,
        showIcon = _props.showIcon,
        icon = _props.icon,
        draggable = _props.draggable,
        checkable = _props.checkable,
        checkStrictly = _props.checkStrictly,
        disabled = _props.disabled,
        loadData = _props.loadData,
        filterTreeNode = _props.filterTreeNode,
        openTransitionName = _props.openTransitionName,
        openAnimation = _props.openAnimation;


    return {
      rcTree: {
        // root: this,

        prefixCls: prefixCls,
        selectable: selectable,
        showIcon: showIcon,
        icon: icon,
        draggable: draggable,
        checkable: checkable,
        checkStrictly: checkStrictly,
        disabled: disabled,
        openTransitionName: openTransitionName,
        openAnimation: openAnimation,

        loadData: loadData,
        filterTreeNode: filterTreeNode,
        renderTreeNode: this.renderTreeNode,
        isKeyChecked: this.isKeyChecked,

        onNodeExpand: this.onNodeExpand,
        onNodeSelect: this.onNodeSelect,
        onNodeMouseEnter: this.onNodeMouseEnter,
        onNodeMouseLeave: this.onNodeMouseLeave,
        onNodeContextMenu: this.onNodeContextMenu,
        onNodeDragStart: this.onNodeDragStart,
        onNodeDragEnter: this.onNodeDragEnter,
        onNodeDragOver: this.onNodeDragOver,
        onNodeDragLeave: this.onNodeDragLeave,
        onNodeDragEnd: this.onNodeDragEnd,
        onNodeDrop: this.onNodeDrop,
        onBatchNodeCheck: this.onBatchNodeCheck,
        onCheckConductFinished: this.onCheckConductFinished
      }
    };
  };

  Tree.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    var _this2 = this;

    // React 16 will not trigger update if new state is null
    this.setState(function (prevState) {
      return _this2.getSyncProps(nextProps, _this2.props, prevState);
    });
  };

  /**
   * [Legacy] Select handler is less small than node,
   * so that this will trigger when drag enter node or select handler.
   * This is a little tricky if customize css without padding.
   * Better for use mouse move event to refresh drag state.
   * But let's just keep it to avoid event trigger logic change.
   */


  /**
   * This will cache node check status to optimize update process.
   * When Tree get trigger `onCheckConductFinished` will flush all the update.
   */


  /**
   * When top `onCheckConductFinished` called, will execute all batch update.
   * And trigger `onCheck` event.
   */


  /**
   * Sync state with props if needed
   */


  /**
   * Only update the value which is not in props
   */


  /**
   * [Legacy] Original logic use `key` as tracking clue.
   * We have to use `cloneElement` to pass `key`.
   */


  Tree.prototype.render = function render() {
    var _classNames;

    var _props2 = this.props,
        prefixCls = _props2.prefixCls,
        className = _props2.className,
        focusable = _props2.focusable,
        showLine = _props2.showLine,
        children = _props2.children;

    var domProps = {};

    // [Legacy] Commit: 0117f0c9db0e2956e92cb208f51a42387dfcb3d1
    if (focusable) {
      domProps.tabIndex = '0';
      domProps.onKeyDown = this.onKeyDown;
    }

    return React.createElement(
      'ul',
      _extends({}, domProps, {
        className: classNames(prefixCls, className, (_classNames = {}, _classNames[prefixCls + '-show-line'] = showLine, _classNames)),
        role: 'tree-node',
        unselectable: 'on'
      }),
      React.Children.map(children, this.renderTreeNode, this)
    );
  };

  return Tree;
}(React.Component);

Tree.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  showLine: PropTypes.bool,
  showIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  focusable: PropTypes.bool,
  selectable: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  checkable: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  checkStrictly: PropTypes.bool,
  draggable: PropTypes.bool,
  defaultExpandParent: PropTypes.bool,
  autoExpandParent: PropTypes.bool,
  defaultExpandAll: PropTypes.bool,
  defaultExpandedKeys: PropTypes.arrayOf(PropTypes.string),
  expandedKeys: PropTypes.arrayOf(PropTypes.string),
  defaultCheckedKeys: PropTypes.arrayOf(PropTypes.string),
  checkedKeys: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  onExpand: PropTypes.func,
  onCheck: PropTypes.func,
  onSelect: PropTypes.func,
  loadData: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onRightClick: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragOver: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrop: PropTypes.func,
  filterTreeNode: PropTypes.func,
  openTransitionName: PropTypes.string,
  openAnimation: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
Tree.childContextTypes = contextTypes;
Tree.defaultProps = {
  prefixCls: 'rc-tree',
  showLine: false,
  showIcon: true,
  selectable: true,
  multiple: false,
  checkable: false,
  disabled: false,
  checkStrictly: false,
  draggable: false,
  defaultExpandParent: true,
  autoExpandParent: false,
  defaultExpandAll: false,
  defaultExpandedKeys: [],
  defaultCheckedKeys: [],
  defaultSelectedKeys: [],
  onExpand: null,
  onCheck: null,
  onSelect: null,
  onDragStart: null,
  onDragEnter: null,
  onDragOver: null,
  onDragLeave: null,
  onDrop: null,
  onDragEnd: null,
  onMouseEnter: null,
  onMouseLeave: null
};

var _initialiseProps = function _initialiseProps() {
  var _this3 = this;

  this.onNodeDragStart = function (event, node) {
    var expandedKeys = _this3.state.expandedKeys;
    var onDragStart = _this3.props.onDragStart;
    var _node$props = node.props,
        eventKey = _node$props.eventKey,
        children = _node$props.children;


    _this3.dragNode = node;

    _this3.setState({
      dragNodesKeys: getDragNodesKeys(children, node),
      expandedKeys: arrDel(expandedKeys, eventKey)
    });

    if (onDragStart) {
      onDragStart({ event: event, node: node });
    }
  };

  this.onNodeDragEnter = function (event, node) {
    var expandedKeys = _this3.state.expandedKeys;
    var onDragEnter = _this3.props.onDragEnter;
    var _node$props2 = node.props,
        pos = _node$props2.pos,
        eventKey = _node$props2.eventKey;


    var dropPosition = calcDropPosition(event, node);

    // Skip if drag node is self
    if (_this3.dragNode.props.eventKey === eventKey && dropPosition === 0) {
      _this3.setState({
        dragOverNodeKey: '',
        dropPosition: null
      });
      return;
    }

    // Ref: https://github.com/react-component/tree/issues/132
    // Add timeout to let onDragLevel fire before onDragEnter,
    // so that we can clean drag props for onDragLeave node.
    // Macro task for this:
    // https://html.spec.whatwg.org/multipage/webappapis.html#clean-up-after-running-script
    setTimeout(function () {
      // Update drag over node
      _this3.setState({
        dragOverNodeKey: eventKey,
        dropPosition: dropPosition
      });

      // Side effect for delay drag
      if (!_this3.delayedDragEnterLogic) {
        _this3.delayedDragEnterLogic = {};
      }
      Object.keys(_this3.delayedDragEnterLogic).forEach(function (key) {
        clearTimeout(_this3.delayedDragEnterLogic[key]);
      });
      _this3.delayedDragEnterLogic[pos] = setTimeout(function () {
        var newExpandedKeys = arrAdd(expandedKeys, eventKey);
        _this3.setState({
          expandedKeys: newExpandedKeys
        });

        if (onDragEnter) {
          onDragEnter({ event: event, node: node, expandedKeys: newExpandedKeys });
        }
      }, 400);
    }, 0);
  };

  this.onNodeDragOver = function (event, node) {
    var onDragOver = _this3.props.onDragOver;
    var eventKey = node.props.eventKey;

    // Update drag position

    if (_this3.dragNode && eventKey === _this3.state.dragOverNodeKey) {
      var dropPosition = calcDropPosition(event, node);

      if (dropPosition === _this3.state.dropPosition) return;

      _this3.setState({
        dropPosition: dropPosition
      });
    }

    if (onDragOver) {
      onDragOver({ event: event, node: node });
    }
  };

  this.onNodeDragLeave = function (event, node) {
    var onDragLeave = _this3.props.onDragLeave;


    _this3.setState({
      dragOverNodeKey: ''
    });

    if (onDragLeave) {
      onDragLeave({ event: event, node: node });
    }
  };

  this.onNodeDragEnd = function (event, node) {
    var onDragEnd = _this3.props.onDragEnd;

    _this3.setState({
      dragOverNodeKey: ''
    });
    if (onDragEnd) {
      onDragEnd({ event: event, node: node });
    }
  };

  this.onNodeDrop = function (event, node) {
    var _state = _this3.state,
        dragNodesKeys = _state.dragNodesKeys,
        dropPosition = _state.dropPosition;
    var onDrop = _this3.props.onDrop;
    var _node$props3 = node.props,
        eventKey = _node$props3.eventKey,
        pos = _node$props3.pos;


    _this3.setState({
      dragOverNodeKey: '',
      dropNodeKey: eventKey
    });

    if (dragNodesKeys.indexOf(eventKey) !== -1) {
      warning(false, 'Can not drop to dragNode(include it\'s children node)');
      return;
    }

    var posArr = posToArr(pos);

    var dropResult = {
      event: event,
      node: node,
      dragNode: _this3.dragNode,
      dragNodesKeys: dragNodesKeys.slice(),
      dropPosition: dropPosition + Number(posArr[posArr.length - 1])
    };

    if (dropPosition !== 0) {
      dropResult.dropToGap = true;
    }

    if (onDrop) {
      onDrop(dropResult);
    }
  };

  this.onNodeSelect = function (e, treeNode) {
    var selectedKeys = _this3.state.selectedKeys;
    var _props3 = _this3.props,
        onSelect = _props3.onSelect,
        multiple = _props3.multiple,
        children = _props3.children;
    var _treeNode$props = treeNode.props,
        selected = _treeNode$props.selected,
        eventKey = _treeNode$props.eventKey;

    var targetSelected = !selected;

    // Update selected keys
    if (!targetSelected) {
      selectedKeys = arrDel(selectedKeys, eventKey);
    } else if (!multiple) {
      selectedKeys = [eventKey];
    } else {
      selectedKeys = arrAdd(selectedKeys, eventKey);
    }

    // [Legacy] Not found related usage in doc or upper libs
    // [Legacy] TODO: add optimize prop to skip node process
    var selectedNodes = [];
    if (selectedKeys.length) {
      traverseTreeNodes(children, function (_ref2) {
        var node = _ref2.node,
            key = _ref2.key;

        if (selectedKeys.indexOf(key) !== -1) {
          selectedNodes.push(node);
        }
      });
    }

    _this3.setUncontrolledState({ selectedKeys: selectedKeys });

    if (onSelect) {
      var eventObj = {
        event: 'select',
        selected: targetSelected,
        node: treeNode,
        selectedNodes: selectedNodes
      };
      onSelect(selectedKeys, eventObj);
    }
  };

  this.onBatchNodeCheck = function (key, checked, halfChecked, startNode) {
    if (startNode) {
      _this3.checkedBatch = {
        treeNode: startNode,
        checked: checked,
        list: []
      };
    }

    // This code should never called
    if (!_this3.checkedBatch) {
      _this3.checkedBatch = {
        list: []
      };
      warning(false, 'Checked batch not init. This should be a bug. Please fire a issue.');
    }

    _this3.checkedBatch.list.push({ key: key, checked: checked, halfChecked: halfChecked });
  };

  this.onCheckConductFinished = function () {
    var _state2 = _this3.state,
        checkedKeys = _state2.checkedKeys,
        halfCheckedKeys = _state2.halfCheckedKeys;
    var _props4 = _this3.props,
        onCheck = _props4.onCheck,
        checkStrictly = _props4.checkStrictly,
        children = _props4.children;

    // Use map to optimize update speed

    var checkedKeySet = {};
    var halfCheckedKeySet = {};

    checkedKeys.forEach(function (key) {
      checkedKeySet[key] = true;
    });
    halfCheckedKeys.forEach(function (key) {
      halfCheckedKeySet[key] = true;
    });

    // Batch process
    _this3.checkedBatch.list.forEach(function (_ref3) {
      var key = _ref3.key,
          checked = _ref3.checked,
          halfChecked = _ref3.halfChecked;

      checkedKeySet[key] = checked;
      halfCheckedKeySet[key] = halfChecked;
    });
    var newCheckedKeys = Object.keys(checkedKeySet).filter(function (key) {
      return checkedKeySet[key];
    });
    var newHalfCheckedKeys = Object.keys(halfCheckedKeySet).filter(function (key) {
      return halfCheckedKeySet[key];
    });

    // Trigger onChecked
    var selectedObj = void 0;

    var eventObj = {
      event: 'check',
      node: _this3.checkedBatch.treeNode,
      checked: _this3.checkedBatch.checked
    };

    if (checkStrictly) {
      selectedObj = getStrictlyValue(newCheckedKeys, newHalfCheckedKeys);

      // [Legacy] TODO: add optimize prop to skip node process
      eventObj.checkedNodes = [];
      traverseTreeNodes(children, function (_ref4) {
        var node = _ref4.node,
            key = _ref4.key;

        if (checkedKeySet[key]) {
          eventObj.checkedNodes.push(node);
        }
      });

      _this3.setUncontrolledState({ checkedKeys: newCheckedKeys });
    } else {
      selectedObj = newCheckedKeys;

      // [Legacy] TODO: add optimize prop to skip node process
      eventObj.checkedNodes = [];
      eventObj.checkedNodesPositions = []; // [Legacy] TODO: not in API
      eventObj.halfCheckedKeys = newHalfCheckedKeys; // [Legacy] TODO: not in API
      traverseTreeNodes(children, function (_ref5) {
        var node = _ref5.node,
            pos = _ref5.pos,
            key = _ref5.key;

        if (checkedKeySet[key]) {
          eventObj.checkedNodes.push(node);
          eventObj.checkedNodesPositions.push({ node: node, pos: pos });
        }
      });

      _this3.setUncontrolledState({
        checkedKeys: newCheckedKeys,
        halfCheckedKeys: newHalfCheckedKeys
      });
    }

    if (onCheck) {
      onCheck(selectedObj, eventObj);
    }

    // Clean up
    _this3.checkedBatch = null;
  };

  this.onNodeExpand = function (e, treeNode) {
    var expandedKeys = _this3.state.expandedKeys;
    var _props5 = _this3.props,
        onExpand = _props5.onExpand,
        loadData = _props5.loadData;
    var _treeNode$props2 = treeNode.props,
        eventKey = _treeNode$props2.eventKey,
        expanded = _treeNode$props2.expanded;

    // Update selected keys

    var index = expandedKeys.indexOf(eventKey);
    var targetExpanded = !expanded;

    warning(expanded && index !== -1 || !expanded && index === -1, 'Expand state not sync with index check');

    if (targetExpanded) {
      expandedKeys = arrAdd(expandedKeys, eventKey);
    } else {
      expandedKeys = arrDel(expandedKeys, eventKey);
    }

    _this3.setUncontrolledState({ expandedKeys: expandedKeys });

    if (onExpand) {
      onExpand(expandedKeys, { node: treeNode, expanded: targetExpanded });
    }

    // Async Load data
    if (targetExpanded && loadData) {
      return loadData(treeNode).then(function () {
        // [Legacy] Refresh logic
        _this3.setUncontrolledState({ expandedKeys: expandedKeys });
      });
    }

    return null;
  };

  this.onNodeMouseEnter = function (event, node) {
    var onMouseEnter = _this3.props.onMouseEnter;

    if (onMouseEnter) {
      onMouseEnter({ event: event, node: node });
    }
  };

  this.onNodeMouseLeave = function (event, node) {
    var onMouseLeave = _this3.props.onMouseLeave;

    if (onMouseLeave) {
      onMouseLeave({ event: event, node: node });
    }
  };

  this.onNodeContextMenu = function (event, node) {
    var onRightClick = _this3.props.onRightClick;

    if (onRightClick) {
      event.preventDefault();
      onRightClick({ event: event, node: node });
    }
  };

  this.getSyncProps = function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var prevProps = arguments[1];
    var preState = arguments[2];

    var needSync = false;
    var oriState = preState || _this3.state;
    var newState = {};
    var myPrevProps = prevProps || {};

    function checkSync(name) {
      if (props[name] !== myPrevProps[name]) {
        needSync = true;
        return true;
      }
      return false;
    }

    // Children change will affect check box status.
    // And no need to check when prev props not provided
    if (prevProps && checkSync('children')) {
      var newCheckedKeys = calcCheckedKeys(props.checkedKeys || oriState.checkedKeys, props);

      var _ref6 = newCheckedKeys || {},
          _ref6$checkedKeys = _ref6.checkedKeys,
          checkedKeys = _ref6$checkedKeys === undefined ? [] : _ref6$checkedKeys,
          _ref6$halfCheckedKeys = _ref6.halfCheckedKeys,
          halfCheckedKeys = _ref6$halfCheckedKeys === undefined ? [] : _ref6$halfCheckedKeys;

      newState.checkedKeys = checkedKeys;
      newState.halfCheckedKeys = halfCheckedKeys;
    }

    // Re-calculate when autoExpandParent or expandedKeys changed
    if (prevProps && (checkSync('autoExpandParent') || checkSync('expandedKeys'))) {
      newState.expandedKeys = props.autoExpandParent ? calcExpandedKeys(props.expandedKeys, props) : props.expandedKeys;
    }

    if (checkSync('selectedKeys')) {
      newState.selectedKeys = calcSelectedKeys(props.selectedKeys, props);
    }

    if (checkSync('checkedKeys')) {
      var _ref7 = calcCheckedKeys(props.checkedKeys, props) || {},
          _ref7$checkedKeys = _ref7.checkedKeys,
          _checkedKeys = _ref7$checkedKeys === undefined ? [] : _ref7$checkedKeys,
          _ref7$halfCheckedKeys = _ref7.halfCheckedKeys,
          _halfCheckedKeys = _ref7$halfCheckedKeys === undefined ? [] : _ref7$halfCheckedKeys;

      newState.checkedKeys = _checkedKeys;
      newState.halfCheckedKeys = _halfCheckedKeys;
    }

    return needSync ? newState : null;
  };

  this.setUncontrolledState = function (state) {
    var needSync = false;
    var newState = {};

    Object.keys(state).forEach(function (name) {
      if (name in _this3.props) return;

      needSync = true;
      newState[name] = state[name];
    });

    if (needSync) {
      _this3.setState(newState);
    }
  };

  this.isKeyChecked = function (key) {
    var _state$checkedKeys = _this3.state.checkedKeys,
        checkedKeys = _state$checkedKeys === undefined ? [] : _state$checkedKeys;

    return checkedKeys.indexOf(key) !== -1;
  };

  this.renderTreeNode = function (child, index) {
    var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var _state3 = _this3.state,
        _state3$expandedKeys = _state3.expandedKeys,
        expandedKeys = _state3$expandedKeys === undefined ? [] : _state3$expandedKeys,
        _state3$selectedKeys = _state3.selectedKeys,
        selectedKeys = _state3$selectedKeys === undefined ? [] : _state3$selectedKeys,
        _state3$halfCheckedKe = _state3.halfCheckedKeys,
        halfCheckedKeys = _state3$halfCheckedKe === undefined ? [] : _state3$halfCheckedKe,
        dragOverNodeKey = _state3.dragOverNodeKey,
        dropPosition = _state3.dropPosition;

    _objectDestructuringEmpty(_this3.props);

    var pos = getPosition(level, index);
    var key = child.key || pos;

    return React.cloneElement(child, {
      eventKey: key,
      expanded: expandedKeys.indexOf(key) !== -1,
      selected: selectedKeys.indexOf(key) !== -1,
      checked: _this3.isKeyChecked(key),
      halfChecked: halfCheckedKeys.indexOf(key) !== -1,
      pos: pos,

      // [Legacy] Drag props
      dragOver: dragOverNodeKey === key && dropPosition === 0,
      dragOverGapTop: dragOverNodeKey === key && dropPosition === -1,
      dragOverGapBottom: dragOverNodeKey === key && dropPosition === 1
    });
  };
};

export default Tree;