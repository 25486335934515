import React from 'react';
import { DatePicker } from 'antd';
import YearPicker from './YearPicker';
import SwitchPicker from './SwitchPicker';

import HOCWrap from './HOCWrap';

import styles from './index.scss';

class Index extends React.Component {
  render() {
    const { className, dropdownClassName, ...rest } = this.props;

    return (
      <DatePicker
        className={[styles.ui, className]}
        dropdownClassName={[styles.drop, dropdownClassName]}
        {...rest}
      />
    );
  }
}

Index.MonthPicker = HOCWrap(DatePicker.MonthPicker);
Index.RangePicker = HOCWrap(DatePicker.RangePicker);
Index.WeekPicker = HOCWrap(DatePicker.WeekPicker);
Index.YearPicker = YearPicker;

export const MonthPicker = Index.MonthPicker;
export const RangePicker = Index.RangePicker;
export { YearPicker, SwitchPicker };

export default Index;
