import { getData, getDataParti } from '@services/request';

/**
 * 查询左边菜单栏权限
 */
export const getRightList = getDataParti(
  'applicationPlatform',
  'leftMenuList',
  (accountId, enterpriseId) => ({ accountId, enterpriseId }),
);

export const checkRole = getDataParti(
  'applicationPlatform',
  'checkAdminRole',
  (accountId, enterpriseId) => ({ accountId, enterpriseId }),
);

/**
 * 权限枚举表
 */
export const ACCESS_CODE = {
  /**
   * 个人中心页面
   */
  VIEW_USERCENTER: 'usercenter',
  /**
   * 权限管理页面
   */
  VIEW_RIGHTMANAGE: '5zv8d5063302646',

  /**
   * 企业信息
   */
  VIEW_ENTERPRISES: '5zv8d5063302643',

  /**
   * 站点信息
   */
  VIEW_STATIONS: '5zv8d5063302644',

  /**
   * 变压器健康管理
   */
  VIEW_TRANSFORMER_EXAMINE: '5zv8d5063302653',

  /**
   * 报表配置
   */
  VIEW_REPORTTOOL: '5zv8d5063302645',

  /**
   * 用能标签
   */
  VIEW_ENERGY_TAG: '5zv8d5063302647',
};
