import React from 'react';
import { Table as AntdTable } from 'antd';
import { multiClass } from '../tool';

import OverflowTd from './OverflowTd';

import styles from './index.scss';

class Table extends React.PureComponent {
  render() {
    const { className, zebra = false, ...rest } = this.props;
    const { bordered } = rest;
    return (
      <AntdTable
        {...rest}
        className={multiClass(styles.ui, zebra ? styles.zebra : null, bordered ? null : styles.noborder, className)}
        pagination={false}
      />
    );
  }
}

Table.OverflowTd = OverflowTd;
Table.Column = Table.Column;
Table.ColumnGroup = Table.ColumnGroup;

export default Table;
