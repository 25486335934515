import cssAnimation from 'css-animation';
import raf from 'raf';
function animate(node, show, done) {
    var height = void 0;
    var requestAnimationFrameId = void 0;
    return cssAnimation(node, 'ant-motion-collapse', {
        start: function start() {
            if (!show) {
                node.style.height = node.offsetHeight + 'px';
                node.style.opacity = '1';
            } else {
                height = node.offsetHeight;
                node.style.height = '0px';
                node.style.opacity = '0';
            }
        },
        active: function active() {
            if (requestAnimationFrameId) {
                raf.cancel(requestAnimationFrameId);
            }
            requestAnimationFrameId = raf(function () {
                node.style.height = (show ? height : 0) + 'px';
                node.style.opacity = show ? '1' : '0';
            });
        },
        end: function end() {
            if (requestAnimationFrameId) {
                raf.cancel(requestAnimationFrameId);
            }
            node.style.height = '';
            node.style.opacity = '';
            done();
        }
    });
}
var animation = {
    enter: function enter(node, done) {
        return animate(node, true, done);
    },
    leave: function leave(node, done) {
        return animate(node, false, done);
    },
    appear: function appear(node, done) {
        return animate(node, true, done);
    }
};
export default animation;