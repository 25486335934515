import React from 'react';
import classNames from 'classnames';
import Input from './Input';
import SvgIcon from '../SvgIcon';
import Button from '../Button';

import styles from './Search.scss';

const SearchIcon = props => <div className={styles.searchIcon} {...props}><SvgIcon xlinkHref="gongnengtubiao_chaxun" /></div>;

export default class Search extends React.Component {
  static defaultProps = {
    inputPrefixCls: 'ant-input',
    prefixCls: 'ant-input-search',
    enterButton: false,
    showClear: true,
  };

  state = {
    value: this.props.defaultValue || '',
  }

  static getDerivedStateFromProps(props) {
    if ('value' in props) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  onSearch = (e) => {
    const { onSearch } = this.props;
    const inputDom = this.input.input;
    if (onSearch) {
      onSearch(inputDom.value, e);
    }
    inputDom.focus();
  }

  onChange = (e) => {
    const { onChange } = this.props;
    if (onChange) onChange(e);
    this.setState({ value: e.target.value });
  }

  onClear = () => {
    const { onChange } = this.props;
    if (onChange) onChange({ target: { value: '' } });
    this.setState({ value: '' });
  }

  getButtonOrIcon() {
    const { enterButton, prefixCls, size, disabled } = this.props;
    const enterButtonAsElement = enterButton;
    let node;
    if (!enterButton) {
      node = <SearchIcon key="searchIcon" />;
    } else if (enterButtonAsElement.type === Button || enterButtonAsElement.type === 'button') {
      node = React.cloneElement(enterButtonAsElement, enterButtonAsElement.type === Button ? {
        className: `${prefixCls}-button`,
        size,
      } : {});
    } else {
      node = (
        <Button
          className={`${prefixCls}-button`}
          type="primary"
          size={size}
          disabled={disabled}
          key="enterButton"
        >
          {enterButton === true ? <SearchIcon key="searchIcon" /> : enterButton}
        </Button>
      );
    }
    return React.cloneElement(node, {
      onClick: this.onSearch,
    });
  }

  getClearIcon() {
    const { showClear } = this.props;
    if (!showClear) return null;
    return (
      <span
        key="clearIcon"
        className={styles.clearIcon}
        style={{ visibility: !this.state.value ? 'hidden' : '' }}
        onClick={this.onClear}
      >
        <SvgIcon xlinkHref="kongjiantubiao_de" />
      </span>
    );
  }

  saveInput = (node) => {
    this.input = node;
  }

  blur() {
    this.input.input.blur();
  }

  focus() {
    this.input.input.focus();
  }

  input;

  render() {
    const { className, prefixCls, inputPrefixCls, size, suffix = null, showClear, enterButton, ...others } = this.props;
    delete others.onSearch;
    delete others.value;
    delete others.onChange;
    const buttonOrIcon = this.getButtonOrIcon();
    const clearIcon = this.getClearIcon();
    const searchSuffix = [suffix, clearIcon, buttonOrIcon];
    const inputClassName = classNames(prefixCls, styles.ui, className, {
      [`${prefixCls}-enter-button`]: !!enterButton,
      [`${prefixCls}-${size}`]: !!size,
      [styles.hasClear]: !!showClear,
    });
    return (
      <Input
        onPressEnter={this.onSearch}
        {...others}
        size={size}
        onChange={this.onChange}
        className={inputClassName}
        prefixCls={inputPrefixCls}
        suffix={searchSuffix}
        ref={this.saveInput}
        value={this.state.value}
      />
    );
  }
}
