import * as services from '@services/environment';

export default {
  state: {
    urls: {},
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *init(_, { put }) {
      yield put({ type: 'queryUrlByName', payload: { name: 'exportToolStationStatement' } });
    },

    *queryUrlByName({ payload: { name } = {} }, { call, put, select }) {
      if (typeof name !== 'string' && !name.length) return;

      const urls = yield select(state => state.environment.urls);

      const url = yield call(services.getPlatformUrl, name);

      yield put({
        type: 'update',
        payload: {
          urls: {
            ...urls,
            [name]: url?.data || '',
          },
        },
      });
    },
  },

  subscriptions: {
  },
};
