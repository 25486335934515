import React from 'react';

import styles from './Filter.scss';

export default function FilterGroup(props) {
  const { className, style, children } = props;
  return (
    <div className={[styles.filterGroup, className]} style={style}>{children}</div>
  );
}
