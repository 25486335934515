import * as services from '@services/bizlog/users';

export default {
  namespace: 'bizlogUser',

  state: {
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *createItem({ payload: { ownerId: ownerIdProp, accountName, actionName, apiInfo, objectId, objectName, objectType, description, operateStatus } = {} }, { call, select }) {
      const { currentEnterpriseId: enterpriseId } = yield select(state => state.base);
      const { accountId } = yield select(state => state.user);

      const [e, res] = yield call(services.createItem, {
        accountId,
        user: accountName,
        ownerId: ownerIdProp || enterpriseId,

        actionName,
        apiInfo,
        objectId,
        objectName,
        objectType,
        description,
        operateStatus,
      });

      return [e, res];
    },

    *createItemLogin({ payload: {  } = {} }, { put, select }) {  // eslint-disable-line
      const { accountId } = yield select(state => state.user);
      yield put({
        type: 'createItem',
        payload: {
          actionName: '登录',
          apiInfo: '/login',
          objectId: accountId,
          objectName: undefined,
          objectType: undefined,
          description: `登录平台`,  // eslint-disable-line
          operateStatus: '0',
          failReason: undefined,
        },
      });
    },

    *createItemLogout({ payload: { cb } = {} }, { put, select }) {
      const { accountId } = yield select(state => state.user);
      yield put({
        type: 'createItem',
        payload: {
          actionName: '登出',
          apiInfo: '/login',
          objectId: accountId,
          objectName: undefined,
          objectType: undefined,
          description: `登出平台`,  // eslint-disable-line
          operateStatus: '0',
          failReason: undefined,
        },
      });

      if (typeof cb === 'function') cb();
    },
  },

  subscriptions: {},
};
