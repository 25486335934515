import React from 'react';
import { Spin } from 'antd';
import { multiClass } from '../tool';

import styles from './index.scss';

function Index({ wrapperClassName, ...rest }) {
  return (
    <Spin
      data-ui
      {...rest}
      wrapperClassName={multiClass(styles.ui, wrapperClassName)}
    />
  );
}

export default Index;
