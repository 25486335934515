import React from 'react';
import PropTypes from 'prop-types';
import { multiClass } from '../tool';

import styles from './index.scss';

const defaultSpeedPixel = 40;
const defaultDelay = 0;

class Index extends React.Component {
  static propTypes = {
    className: PropTypes.number,
    speedPixel: PropTypes.number,
    delay: PropTypes.number,
  }

  state = {
    key: moment().valueOf(),
    animationDuration: 0,
  }

  componentDidMount() {
    this.setAnimationDuration();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.children !== nextProps.children) {
      this.setState({
        key: moment().valueOf(),
      }, () => {
        this.setAnimationDuration();
      });
    }
  }

  setAnimationDuration() {
    const { speedPixel = defaultSpeedPixel, delay = defaultDelay } = this.props;
    if (this.elBlock && this.elInline) {
      const w1 = this.elBlock.clientWidth;
      const w2 = this.elInline.clientWidth;

      if (w1 && w2) {
        const animationDuration = ((w1 + w2) / speedPixel) + delay;

        this.setState({
          animationDuration,
        });
      }
    }
  }

  render() {
    const { key, animationDuration } = this.state;
    const { className, children } = this.props;

    return (
      <div key={key} className={multiClass(styles.ui, className)}>
        <div ref={(e) => { this.elBlock = e; }} className={styles.block} style={{ animationDuration: `${animationDuration}s` }}>
          <div ref={(e) => { this.elInline = e; }} className={styles.inline} style={{ animationDuration: `${animationDuration}s` }}>{ children }</div>
        </div>
      </div>
    );
  }
}


export default Index;
