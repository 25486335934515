import { detect } from 'detect-browser';
import router from 'umi/router';

import { listenTokenRolling, listenUnauthorized } from '@utils/request';
import * as storage from '@services/storage';
import * as wsocket from '@services/socketCluster';

const defaultMinWidth = 1903;  // 1920 - 17
const defaultMinHeight = 930;  // 949 - 17, 缓冲2

const defaultLoadingKeys = {
  models: [],
  effects: [
    'base/init',
  ],
};

export default {
  state: {
    browser: {},
    os: {},
    toLocation: '/',

    user: {},

    minWidth: defaultMinWidth,
    minHeight: defaultMinHeight,

    loadingKeys: defaultLoadingKeys,

    // 兼容帐号权限，理论上这里的值为超管帐号所属企业
    currentEnterpriseId: undefined,
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *init(_, { put, select, take }) {
      yield put({ type: 'environment/init' });
      yield take('environment/init/@@end');

      yield put({ type: 'user/fetch' });
      yield take('user/fetch/@@end');
    },

    *reLocate(_, { put, select }) {
      const toLocation = yield select(state => state.base.toLocation);
      router.push(toLocation);
    },

    *requestMinView({ payload: { minHeight, minWidth, reset = false } = {} }, { put }) {
      if (reset) {
        yield put({
          type: 'update',
          payload: {
            minWidth: defaultMinWidth,
            minHeight: defaultMinHeight,
          },
        });

        return;
      }
      if (minWidth) yield put({ type: 'update', payload: minWidth });
      if (minHeight) yield put({ type: 'update', payload: minHeight });
    },

    *requestLoadingKeys({ payload: { models = [], effects = [] } = {} }, { put }) {
      if (Array.isArray(models) && Array.isArray(effects)) {
        yield put({
          type: 'update',
          payload: {
            loadingKeys: {
              models: [
                ...models,
                ...defaultLoadingKeys.models,
              ],
              effects: [
                ...effects,
                ...defaultLoadingKeys.effects,
              ],
            },
          },
        });
      } else {
        yield put({
          type: 'update',
          payload: {
            loadingKeys: defaultLoadingKeys,
          },
        });
      }
    },
  },

  subscriptions: {
    initUserAgent({ dispatch }) {
      const browser = detect() || {};
      const body = document.body;
      browser.version = window.parseInt(browser.version);

      const osArr = (browser.os || '').split(/\s/);
      const [osName = '', osVersion = ''] = osArr;
      const os = { name: osName.toLowerCase(), version: osVersion.toLowerCase() };

      dispatch({ type: 'update', payload: { browser, os } });

      // add class for style hacks
      // IE does not support multiple parameters for the add() & remove() methods
      body.classList.add(browser.name);
      body.classList.add(`${browser.name}${browser.version}`);
      body.classList.add(os.name);
      body.classList.add(`${os.name}${os.version}`);
    },

    initTokenRolling() {
      // @utils/request 中循环触发了心跳
      // 如果服务器返回了相应的响应头，则会带上 token 传到这里
      listenTokenRolling((token) => {
        storage.set('token', token);
      });
    },

    initUnauthorized({ dispatch }) {
      listenUnauthorized(() => {
        console.log('Unauthorized');
        wsocket.unregiterAll();
        dispatch({ type: 'user/logout' });
      });
    },

    initWSDispatch({ dispatch }) {
      return wsocket.watch((action, data) => {
        dispatch({ type: action, payload: data });
      });
    },
  },
};
