import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import Radio from '../Radio';
import DatePicker, { MonthPicker, YearPicker } from './index';

import styles from './SwitchPicker.scss';

const dateProps = {
  allowClear: false,
  disabledDate: (current) => {
    return current && current.startOf('day').valueOf() > Date.now();
  },
};

class Index extends React.Component {
  static enumMap = {
    day: '日',
    month: '月',
    year: '年',
  }

  static enum = {
    day: 'day',
    month: 'month',
    year: 'year',
  }

  static propTypes = {
    className: PropTypes.string,
    types: PropTypes.arrayOf((propValue, key, componentName) => {
      if (!Index.enumMap[propValue[key]]) {
        return new Error(`${componentName}: types item should be one of SwitchPicker.enum`);
      }
    }),
    selectedType: PropTypes.oneOf(Object.values(Index.enum)),
    onChange: PropTypes.func,
  }

  static defaultProps = {
    className: null,
    types: [Index.enum.day, Index.enum.month],
    selectedType: null,
    onChange: () => { },
  }

  state = {
    value: moment(),
    selectedType: this.props.types ?.[0] || Index.enum.day,
  }

  componentDidUpdate(prevProp) {
    if (JSON.stringify(this.props.types) !== JSON.stringify(prevProp.types)) {
      this.setState({  // eslint-disable-line
        selectedType: this.props.types ?.[0] || Index.enum.day,
      });
    }
  }

  onChange = (value, selectedType, ...args) => {
    const { onChange } = this.props;

    onChange(value, selectedType, ...args);

    this.setState({
      value,
      selectedType,
    });
  }

  render() {
    const {
      className,
      value,
      types,
      selectedType,
      ...rest
    } = this.props;
    const { value: valueState, selectedType: selectedTypeState } = this.state;

    const valueRender = isUndefined(value) ? valueState : value;
    const selectedTypeRender = selectedType || selectedTypeState;
    return (
      <div className={[className, styles.wrap]}>
        {
          types.length > 1 ?
            <Radio.RadioGroup className={styles.switcher} value={selectedTypeRender} onChange={({ target: { value: v } }) => this.onChange(valueRender, v)}>
              {types.map(i => <Radio.Button key={i} tight value={i}>{Index.enumMap[i]}</Radio.Button>)}
            </Radio.RadioGroup> :
            null
        }
        {  /* eslint-disable no-unused-expressions, semi */
          do {
            if (selectedTypeRender === Index.enum.day) {
              <DatePicker
                {...dateProps}
                {...rest}
                value={valueRender}
                onChange={v => this.onChange(v, selectedTypeRender)}
              />
            } else if (selectedTypeRender === Index.enum.month) {
              <MonthPicker
                {...dateProps}
                {...rest}
                value={valueRender}
                onChange={v => this.onChange(v, selectedTypeRender)}
              />
            } else if (selectedTypeRender === Index.enum.year) {
              <YearPicker
                {...dateProps}
                {...rest}
                value={valueRender}
                onChange={v => this.onChange(v, selectedTypeRender)}
              />
            } else {
              null
            }
          }  /* eslint-enable */
      }
      </div>
    );
  }
}

export default Index;
