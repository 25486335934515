import React from 'react';
import * as queryString from 'query-string';
import { get } from '@services/storage';
import { SvgIcon } from '@ui';
import { ACCESS_CODE } from '@openapi/right';

import styles from './Nav.scss';

function urlExtend(url = '', extend = {}) {
  const [uPath = '', queries = ''] = url.split('?');  // ignore multiple symbol ?
  const query = {
    ...queryString.parse(queries),
    ...extend,
  };

  return `${uPath}?${queryString.stringify(query)}`;
}

const NavSvgIcon = ({ className, ...resProps }) => <div className={[styles.svgIcon, className]}><SvgIcon {...resProps} /></div>;

export const pathIsMatch = (menuLink, pathname) => pathname.indexOf(menuLink) === 0;

const simpleMatch = (...args) => pathname => Array.from(args).some(link => pathIsMatch(link, pathname));

const config = (paras = {}) => ([{
  link: '/enterprise',
  title: '企业信息',
  dom: <><NavSvgIcon xlinkHref="daohangtubiao_shouye" />企业信息</>,
  accessKey: ACCESS_CODE.VIEW_ENTERPRISES,
}, {
  title: '站点信息',
  link: '/station',
  dom: <><NavSvgIcon xlinkHref="daohangtubiao_yongnengkaohe" />站点信息</>,
  accessKey: ACCESS_CODE.VIEW_STATIONS,
}, {
  title: '变压器健康管理',
  link: '/transformer-examine',
  dom: <><NavSvgIcon xlinkHref="gongnengtubiao_qushitu" />变压器健康管理</>,
  accessKey: ACCESS_CODE.VIEW_TRANSFORMER_EXAMINE,
}, {
  title: '用能标签',
  link: '/energy-tag',
  dom: <><NavSvgIcon xlinkHref="daohangtubiao_nengyuanjiance" />用能标签</>,
  accessKey: ACCESS_CODE.VIEW_ENERGY_TAG,
}, {
  title: '报表配置',
  link: urlExtend(paras.exportToolStationStatement, { token: get('token') }),
  type: 'blank',
  dom: <><NavSvgIcon xlinkHref="jiancedating" />报表配置</>,
  accessKey: ACCESS_CODE.VIEW_REPORTTOOL,
}]);


export default (paras = {}) => fillAccess(config(paras));

export const menuLinks = [{
  defaultHide: false,
  link: '/usercenter',
  accessKey: ACCESS_CODE.VIEW_USERCENTER,
  title: '个人中心',
  dom: '个人中心',
}, {
  defaultHide: true,
  link: '/rightsmanagement/account',
  title: '权限管理',
  dom: '权限管理',
  accessKey: ACCESS_CODE.VIEW_RIGHTMANAGE,
  match: simpleMatch('/rightsmanagement', '/roleEdit', '/roleDetails'),
}];

// 在可展开层级上注入子级的accessKey
function fillAccess(cfg) {
  return cfg.map((opt) => {
    if (opt.children) {
      opt.accessKey = opt.children.map(o => o.accessKey).join(',');
    }
    return opt;
  });
}

export const matchPathKeys = ((cfg) => {
  function recursion(resList, opt, parentKey) {
    if (opt.children) {
      // 展开节点
      opt.children.forEach((o) => { recursion(resList, o, opt.link); });
    } else {
      // 目标层级
      resList.push((rMap, pathname) => {
        if (!rMap[opt.accessKey]) return false;
        const matchMethod = opt.match || pathIsMatch.bind(null, opt.link);
        if (!matchMethod(pathname)) return false;
        return [opt.link, parentKey];
      });
    }
  }
  const funcList = cfg.reduce((resLst, opt) => {
    recursion(resLst, opt);
    return resLst;
  }, []);


  return (rightMap, pathname) => {
    let res = [];
    funcList.some((f) => {
      const matchRes = f(rightMap, pathname);
      if (!matchRes) return false;
      res = matchRes;
      return true;
    });
    return res;
  };
})(config());

// export const mapAccessKeyToLink = ((cfg) => {
//   const hash = cfg.reduce((h, opt) => {
//     if (opt.children) {
//       opt.children.forEach((o) => {
//         h[o.accessKey] = o.link;
//       });
//     } else {
//       h[opt.accessKey] = opt.link;
//     }
//     return h;
//   }, {});
//
//   return ack => hash[ack];
// })(config().concat(menuLinks));
