import PropTypes from 'prop-types';
import enUs from '../locale/en_US';

function noop() {}

export default {
  propTypes: {
    className: PropTypes.string,
    locale: PropTypes.object,
    style: PropTypes.object,
    visible: PropTypes.bool,
    onSelect: PropTypes.func,
    prefixCls: PropTypes.string,
    onChange: PropTypes.func,
    onOk: PropTypes.func
  },

  getDefaultProps: function getDefaultProps() {
    return {
      locale: enUs,
      style: {},
      visible: true,
      prefixCls: 'rc-calendar',
      className: '',
      onSelect: noop,
      onChange: noop,
      onClear: noop,
      renderFooter: function renderFooter() {
        return null;
      },
      renderSidebar: function renderSidebar() {
        return null;
      }
    };
  },
  shouldComponentUpdate: function shouldComponentUpdate(nextProps) {
    return this.props.visible || nextProps.visible;
  },
  getFormat: function getFormat() {
    var format = this.props.format;
    var _props = this.props,
        locale = _props.locale,
        timePicker = _props.timePicker;

    if (!format) {
      if (timePicker) {
        format = locale.dateTimeFormat;
      } else {
        format = locale.dateFormat;
      }
    }
    return format;
  },
  focus: function focus() {
    if (this.rootInstance) {
      this.rootInstance.focus();
    }
  },
  saveRoot: function saveRoot(root) {
    this.rootInstance = root;
  }
};