import PropTypes from 'prop-types';
import { multiClass } from '../tool';
import styles from './index.scss';

class Index extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedKey: PropTypes.string,
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
    };
  }

  handleClick = (key, index) => {
    const { onChange } = this.props;
    this.setState({
      selectedIndex: index,
    });

    if (typeof onChange === 'function') {
      onChange(key);
    }
  }

  render() {
    const { className, list, selectedKey } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div className={multiClass(styles.wrap, className)}>
        <ul>
          {
            list.map((item, i) => {
              const isActive = selectedKey ? item.key === selectedKey : i === selectedIndex;
              return <li key={item.key} className={isActive ? styles.active : null} onClick={() => this.handleClick(item.key, i)}>{ item.name }</li>;
            })
          }
        </ul>
      </div>
    );
  }
}

export default Index;
