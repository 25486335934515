import { propertyOf, isUndefined, isNull, identity } from 'lodash';

/**
 * 多样式组合
 */
// 弃用,改用classnames库
export function multiStyles(...styles) {
  return styles.join(' ');
}
// 弃用,改用classnames库
export function composeClass(...args) {
  return args.filter(a => a).join(' ');
}
// 弃用,改用classnames库
export function multiClass(...styles) {
  return styles.join(' ');
}

export function readProp(ctx) {
  const get = propertyOf(ctx);
  return function innerReadProp(propName, defaultVal, decorator = identity) {
    let defaultValue = defaultVal;
    if (arguments.length < 2) {
      defaultValue = '';
    }
    const value = get(propName);
    if (isUndefined(value) || isNull(value)) return defaultValue;
    return decorator(value);
  };
}
export const CommonReg = {
  /**
   * 生成数字型数据的校验正则
   * @param {int} integer 整数位长度
   * @param {int} decimal 小数位长度
   * @return {RegExp}
   */
  number(integer, decimal = 0) {
    const reg = new RegExp(!decimal ? `^\\d{1,${integer}}$` : `^\\d{1,${integer}}(\\.\\d{1,${decimal}})?$`);
    return reg;
  },
  /**
   * 名称正则，用途例如：站点名称，站点简称, 人员姓名，人员角色
   * @return {RegExp}
   */
  name() {
    return /^[()（）a-zA-Z0-9\u4e00-\u9fa5]+$/;
  },
  /**
   * 名称正则，用途例如：经纬度
   * @return {RegExp}
   */
  gps() {
    return /^[+-]?\d{1,3}(\.\d{1,6})?,[+-]?\d{1,3}(\.\d{1,6})?$/;
  },
  /**
   * 名称正则，用途例如：去除空格
   * @return {RegExp}
   */
  filterSpace() {
    return /\s/g;
  },
  /**
   * 名称正则，用途例如：验证手机号
   * @return {RegExp}
   */
  mobilePhone() {
    return /^\d{11}$/; // ^1[3456789]\d{9}$/;
  },
  /**
   * 名称正则，用途例如：密码长度
   * @param {int} min 最小长度
   * @param {int} max 最大长度
   * @return {RegExp}
   */
  passwordLength(min = 1, max) {
    return new RegExp(`\\w{${min},${max}}`);
  },
  /**
   * 名称正则，用途例如：告警启动条件
   * @param {int} max 最大长度
   * @return {RegExp}
   */
  loatingPointNumber(max) {
    return new RegExp(`^(\\d+)(\\.\\d{0,${max}})?$`);
  },
  /**
  * 名称正则，用途例如：验证联系电话
  */
  tellPhone() {
    return new RegExp(/^(\+\d{1,6})?-?\+?\d{3,4}-?\d{7,8}$/);
  },

  /**
   * 邮箱正则
   */
  email() {
    return /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
  },

  /**
   * 0-100,两位小数
   */
  percent(){
    return /^(\d|[1-9]\d|100)(\.\d{1,2})?$/;
  }
};

// 兼容旧的代码
export const numberReg = CommonReg.number;

export function phoneNumberReg(integer) {
  const reg = new RegExp(`^[\\d+-]{1,${integer}}$`);
  return reg;
}

export const sleep = t => new Promise(r => window.setTimeout(r, t));

/**
 * form.validateFields 的async写法
 * @param {Object} antd的Form.create 实例
 * @param  {...any} form.validateFields方法除了最后个回调的全部参数
 * @return {Array} form.validateFields的callback的入参的数组结构
 */
export const asyncValidateFields = (form, ...args) => new Promise(r => form.validateFields(...args, (...p) => r([...p])));
