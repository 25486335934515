import _defineProperty from 'babel-runtime/helpers/defineProperty';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _possibleConstructorReturn from 'babel-runtime/helpers/possibleConstructorReturn';
import _inherits from 'babel-runtime/helpers/inherits';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import classnames from 'classnames';

var scrollTo = function scrollTo(element, to, duration) {
  var requestAnimationFrame = window.requestAnimationFrame || function requestAnimationFrameTimeout() {
    return setTimeout(arguments[0], 10);
  };
  // jump to target if duration zero
  if (duration <= 0) {
    element.scrollTop = to;
    return;
  }
  var difference = to - element.scrollTop;
  var perTick = difference / duration * 10;

  requestAnimationFrame(function () {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  });
};

var Select = function (_Component) {
  _inherits(Select, _Component);

  function Select() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Select);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Select.__proto__ || Object.getPrototypeOf(Select)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      active: false
    }, _this.onSelect = function (value) {
      var _this$props = _this.props,
          onSelect = _this$props.onSelect,
          type = _this$props.type;

      onSelect(type, value);
    }, _this.handleMouseEnter = function (e) {
      _this.setState({ active: true });
      _this.props.onMouseEnter(e);
    }, _this.handleMouseLeave = function () {
      _this.setState({ active: false });
    }, _this.saveList = function (node) {
      _this.list = node;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Select, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // jump to selected option
      this.scrollToSelected(0);
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      // smooth scroll to selected option
      if (prevProps.selectedIndex !== this.props.selectedIndex) {
        this.scrollToSelected(120);
      }
    }
  }, {
    key: 'getOptions',
    value: function getOptions() {
      var _this2 = this;

      var _props = this.props,
          options = _props.options,
          selectedIndex = _props.selectedIndex,
          prefixCls = _props.prefixCls;

      return options.map(function (item, index) {
        var _classnames;

        var cls = classnames((_classnames = {}, _defineProperty(_classnames, prefixCls + '-select-option-selected', selectedIndex === index), _defineProperty(_classnames, prefixCls + '-select-option-disabled', item.disabled), _classnames));
        var onclick = null;
        if (!item.disabled) {
          onclick = _this2.onSelect.bind(_this2, item.value);
        }
        return React.createElement(
          'li',
          {
            className: cls,
            key: index,
            onClick: onclick,
            disabled: item.disabled
          },
          item.value
        );
      });
    }
  }, {
    key: 'scrollToSelected',
    value: function scrollToSelected(duration) {
      // move to selected item
      var select = ReactDom.findDOMNode(this);
      var list = ReactDom.findDOMNode(this.list);
      if (!list) {
        return;
      }
      var index = this.props.selectedIndex;
      if (index < 0) {
        index = 0;
      }
      var topOption = list.children[index];
      var to = topOption.offsetTop;
      scrollTo(select, to, duration);
    }
  }, {
    key: 'render',
    value: function render() {
      var _classnames2;

      if (this.props.options.length === 0) {
        return null;
      }

      var prefixCls = this.props.prefixCls;

      var cls = classnames((_classnames2 = {}, _defineProperty(_classnames2, prefixCls + '-select', 1), _defineProperty(_classnames2, prefixCls + '-select-active', this.state.active), _classnames2));

      return React.createElement(
        'div',
        {
          className: cls,
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave
        },
        React.createElement(
          'ul',
          { ref: this.saveList },
          this.getOptions()
        )
      );
    }
  }]);

  return Select;
}(Component);

Select.propTypes = {
  prefixCls: PropTypes.string,
  options: PropTypes.array,
  selectedIndex: PropTypes.number,
  type: PropTypes.string,
  onSelect: PropTypes.func,
  onMouseEnter: PropTypes.func
};


export default Select;