import { getData } from '@services/request';
import { getDataParti } from './request';

/**
 * 获取服务端维护的 metric
 */
export async function getMetricCode() {
  let fres = {};
  const data = await getData('environment', 'getSignalConfiguration', { fileVersion: '3.2' });

  if (data.code === null) {
    fres = data.data;
  }

  return [fres, data];
}

const environment = action => getDataParti('environment', action);

/**
 * 获取websocket地址
 */
export const getWebSocketOption = environment('getWebSocketOption');
/**
 * 获取二次设备地址
 */
export const getSecondaryDeviceRedirectUrl = environment('getSecondaryDeviceRedirectUrl');
/**
 * 获取关联平台URL
 * @param {string} platform 关联平台的关键字
 */
export const getPlatformUrl = platform => environment('getPlatformUrl')({ platform });
/**
 * 获取前台地址
 */
export const getfrontPlatformUrl = environment('getfrontPlatformUrl');
/**
 * 获取接线图地址
 */
export const getWiringDiagramUrl = environment('getWiringDiagramUrl');
