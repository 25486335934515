import React from 'react';
import { multiClass } from '../tool';

import p0 from './assets/0.png';
import p1 from './assets/1.png';
import p2 from './assets/2.png';

import styles from './index.scss';


export default (props) => {
  const {
    className,
    children,
    type,
  } = props;

  let i = '';
  const t = '暂无数据';
  switch (type) {
    default:
    case '0':
      i = p0;
      break;
    case '1':
      i = p1;
      break;
    case '2':
      i = p2;
      break;
  }

  return (
    <div className={multiClass(styles.wrap, className)} style={{ backgroundImage: `url(${i})` }}>{children || t}</div>
  );
};
