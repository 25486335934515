// import React from 'react';
// import { Input } from 'antd';
// import { multiClass } from '../tool';

// import styles from './index.scss';

// const Search = Input.Search;

// class Index extends React.Component {
//   render() {
//     const {
//       className,
//       ...rest
//     } = this.props;
//     return (
//       <Input {...rest} className={multiClass(styles.ui, className)} />
//     );
//   }
// }

// Index.Search = ({ className, ...rest }) => {
//   return (
//     <Search className={multiClass(styles.ui, className)} {...rest} />
//   );
// };

// Index.Group = Input.Group;
// Index.TextArea = Input.TextArea;

// export default Index;

import { Input as OriginInput } from 'antd';
import Input from './Input';
import Search from './Search';

Input.Search = Search;
Input.TextArea = OriginInput.TextArea;

export default Input;
