/**
 * 用户权限model
 */
import * as services from '@openapi/right';
import { readProp } from '@utils/tool';
import routeConfig from '@root/layouts/Nav.config';

const { ACCESS_CODE } = services;

const getInitState = (accountId, enterpriseId) => ({
  accountId,
  enterpriseId,
  defaultAccessKey: ACCESS_CODE.VIEW_USERCENTER,
  defaultAccessRoute: '/',
  rightMap: {
    [ACCESS_CODE.VIEW_USERCENTER]: true,
    [ACCESS_CODE.VIEW_RIGHTMANAGE]: false,
  },
});

export default {
  namespace: 'right',

  state: getInitState(),

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetchAccess({ payload: { accountId, enterpriseId } }, { put, call }) {
      if (!enterpriseId) {
        yield put({ type: 'update', payload: getInitState(accountId) });
        return;
      }
      const res = yield call(services.getRightList, accountId, enterpriseId);
      if (res.code) {
        console.error('获取权限列表失败');
        yield put({ type: 'update', payload: getInitState(accountId, enterpriseId) });
        return;
      }

      const resOf = readProp(res);
      const rightList = resOf('dataList', []).map(o => o.id);
      // 找出默认显示的页面
      const defaultAccessKey = rightList[0] || ACCESS_CODE.VIEW_USERCENTER;
      const routes = routeConfig();
      const defaultAccessRoute = routes.filter(i => (i.type !== 'blank' && rightList.some(j => j === i.accessKey)))[0]?.link || '/usercenter';

      const rightMap = rightList.reduce((h, str) => { h[str] = true; return h; }, {
        [ACCESS_CODE.VIEW_USERCENTER]: true,

        // [ACCESS_CODE.VIEW_RIGHTMANAGE]: true,
        // [ACCESS_CODE.VIEW_ENTERPRISES]: true,
        // [ACCESS_CODE.VIEW_STATIONS]: true,
        // [ACCESS_CODE.VIEW_DANCHAN]: true,
        // [ACCESS_CODE.VIEW_CHANZHI]: true,
        // [ACCESS_CODE.VIEW_YONGNENG]: true,
        // [ACCESS_CODE.VIEW_OUTPUTVALUE]: true,

        /* test */
        // [ACCESS_CODE.statistical1]: true,
        // [ACCESS_CODE.statistical2]: true,
        // [ACCESS_CODE.alarmRecord]: true,
        // [ACCESS_CODE.alarmOperationAlarm]: true,
        // [ACCESS_CODE.VIEW_CHARGE_OPTIMIZE]: true,
        // [ACCESS_CODE.VIEW_DEVICEMANAGE]: true,
        // [ACCESS_CODE.VIEW_EnergyReport]: true,
      });
      yield put({ type: 'update', payload: { accountId, enterpriseId, defaultAccessKey, defaultAccessRoute, rightMap } });
    },

    *clean(_, { put }) {
      yield put({
        type: 'update',
        payload: getInitState(),
      });
    },
  },

  subscriptions: {},
};
