import * as React from 'react';
import { polyfill } from 'react-lifecycles-compat';
import LocaleReceiver from 'antd/es/locale-provider/LocaleReceiver';
import defaultLocale from 'antd/es/locale-provider/default';
import { Tooltip } from 'antd';
import SvgIcon from '../SvgIcon';
import Button from '../Button';
import { multiClass } from '../tool';
import styles from './index.scss';

class Popconfirm extends React.Component {
  static defaultProps = {
    prefixCls: 'ant-popover',
    transitionName: 'zoom-big',
    placement: 'top',
    trigger: 'click',
    okType: 'primary',
    icon: <SvgIcon className={styles.icon} style={{ color: '#FAAD14' }} xlinkHref="xunwen" />,
  };

  static getDerivedStateFromProps(nextProps) {
    if ('visible' in nextProps) {
      return { visible: nextProps.visible };
    } else if ('defaultVisible' in nextProps) {
      return { visible: nextProps.defaultVisible };
    }
    return null;
  }

  tooltip = null

  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
    };
  }

  getPopupDomNode() {
    return this.tooltip.getPopupDomNode();
  }

  onConfirm = (e) => {
    this.setVisible(false, e);

    const { onConfirm } = this.props;
    if (onConfirm) {
      onConfirm.call(this, e);
    }
  }

  onCancel = (e) => {
    this.setVisible(false, e);

    const { onCancel } = this.props;
    if (onCancel) {
      onCancel.call(this, e);
    }
  }

  onVisibleChange = (visible) => {
    this.setVisible(visible);
  }

  setVisible(visible, e) {
    const props = this.props;
    if (!('visible' in props)) {
      this.setState({ visible });
    }

    const { onVisibleChange } = props;
    if (onVisibleChange) {
      onVisibleChange(visible, e);
    }
  }

  saveTooltip = (node) => {
    this.tooltip = node;
  }

  renderOverlay = (popconfirmLocale) => {
    const { prefixCls, okButtonProps, cancelButtonProps, title, cancelText, okText, okType, icon } = this.props;
    return (
      <div>
        <div className={`${prefixCls}-inner-content`}>
          <div className={`${prefixCls}-message`}>
            {icon}
            <div className={`${prefixCls}-message-title`}>{title}</div>
          </div>
          <div className={`${prefixCls}-buttons`}>
            <Button onClick={this.onConfirm} type={okType} size="small" {...okButtonProps}>
              {okText || popconfirmLocale.okText}
            </Button>
            <Button onClick={this.onCancel} size="small" {...cancelButtonProps}>
              {cancelText || popconfirmLocale.cancelText}
            </Button>

          </div>
        </div>
      </div>
    );
  }

  render() {
    const { prefixCls, placement, overlayClassName, ...restProps } = this.props;
    const overlay = (
      <LocaleReceiver
        componentName="Popconfirm"
        defaultLocale={defaultLocale.Popconfirm}
      >
        {this.renderOverlay}
      </LocaleReceiver>
    );
    return (
      <Tooltip
        {...restProps}
        overlayClassName={multiClass(styles.ui, overlayClassName)}
        prefixCls={prefixCls}
        placement={placement}
        onVisibleChange={this.onVisibleChange}
        visible={this.state.visible}
        overlay={overlay}
        ref={this.saveTooltip}
      />
    );
  }
}
polyfill(Popconfirm);
export default Popconfirm;
