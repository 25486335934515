import React from 'react';
import { composeClass } from '@utils/tool';

export default function SvgIcon(props) {
  const { className, xlinkHref, ...restProps } = props;
  return (
    <svg className={composeClass('icon', className)} {...restProps}>
      <use xlinkHref={`#icon-${xlinkHref}`} />
    </svg>
  );
}
