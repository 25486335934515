import { getData } from '@services/request';

export async function login({ username, password, exp = 1 }) {
  let fres = {};
  const data = await getData('user', 'verify', {
    action: 'serviceProviderLogin',
    username,
    password,
    exp,
  });

  if (data.code === null) {
    fres = data.data || {};
  }

  return [fres, data];
}

export async function queryUserInfo({ id }) {
  let fres = {};
  const res = await getData('applicationPlatform', 'queryPersonalData', {
    accountId: id,
  });

  if (res.code === null) {
    fres = res.data || {};
  }

  return [fres, res];
}

export async function getUserInfoByToken() {
  const data = await getData('user', 'getUserInfoByToken');
  return data;
}
