import styles from './index.scss';

export default function HOCWrap(Component) {
  return class extends React.Component {
    render() {
      const { className, dropdownClassName, ...rest } = this.props;

      return (
        <Component
          className={[styles.ui, className]}
          dropdownClassName={[styles.drop, dropdownClassName]}
          {...rest}
        />
      );
    }
  };
}
