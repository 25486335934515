const codeHash = {
  AreaNameExist: '该区域已存在',
  OpenAPIInternalError: 'openapi调用出错',
  ServiceError: '服务错误',
  ServiceUnavailable: '服务不可用',
  // contractExist: '服务商合同已存在',//此限制去掉了
  contractNumberExist: '相同合同编码合同已存在',
  NameExist: '该账号已存在',
  UserAlreadyExists: '账号已存在',
  RoleInUse: '该角色有人正在使用',
  RoleIdEmpty: '角色不能为空',
  EmployeeWithCellphoneExist: '联系电话已存在',
  EmployeeWithNamePositionContactTypeExist: '姓名已存在',
  RoleNameExist: '角色名称已存在',
  RoleNotExist: '角色不存在',
  AuthorityEmpty: '没有选择权限',
  RoleNameEmpty: '角色名称不能为空',
  AccountHasDeactivation: '账号被停用',
  AccountHasLocked: '帐号被锁定',
  PowerStationCodeExist: '该电力户号已存在',
  InvalidPassword: '账号或密码错误',
  InvalidPasswordOrVerificationCode: '账号或密码错误',
  EmployeeNotExist: '用户不存在',
  UserNotExists: '用户不存在',
  // 下发配置错误码
  PointDispatchError: '点下发错误',
  DispatchConfirmError: '下发确认错误',
  SetFail: ' 设置失败',
  SensorExist: '该IMU已与传感器关联，不允许删除',
  SetFailNoAccessKey: 'mqtt下发错误无AccessKey',
  // IMU相关
  ProtocolAddressExist: '通讯地址重复',
  ProductCodeExist: '出厂编号重复',
  SimCodeExist: 'SIM卡号已存在',

  // 导入二次设备的错误码
  emptyParameter: '存在为空的必填字段',
  ServiceErr: '服务调用失败',
  HaveMonitoringPoint: '开关上存在对应监测点信息，请先删除相关监测点信息',
  // ProtocolAddressExist: '逻辑地址已经存在',
  SubCatalogCircuit: '存在下级回路，请先删除',
  SubCatalogMonitoringPoint: '存在下级监测点，请先删除',
  SubCatalogSensor: '存在下级传感器，请先删除',
  SubCatalogMeasurement: '存在下级计量策略，请先删除',
  SubCatalogTransformer: '存在下级变压器，请先删除',
  FirstTransformerExistNotCircuit: '一级变压器下只能有一个进线回路',
  modelExist: '该IMU型号编码已存在',
  generatrixExist: '已存在同名的母线',
  SubCatalogSwitchgears: '存在下级开关柜，请先删除',
  SubCatalogGenerators: '存在下级发电机，请先删除',
  SubCatalogCapacitors: '存在下级电容器，请先删除',
  SubCatalogCapacitorGroups: '存在下级电容器组，请先删除',
  SubCatalogCapacitorGroupRepeatName: '当前用电客户下已存在同名的电容器组',
  SubCatalogSwitchgearRepeatName: '当前用电客户下已存在同名的开关柜',
  CapacitorOverLimit: '当前电容器组下电容器数量达到最大值',
  ModelNameExit: '传感器型号重复',
  IMUModelUsed: 'IMU模板已被使用，不允许删除',
  PortNameExist: '通道号重复，创建失败',
  SameName: '上下级开关回路的名称不能重复',
  ExistSensor: '同一imu下，同一端口的通讯地址不能相同',
  SensorModelUsed: '传感器模板已使用，删除失败',
  IMUNameExist: '存在相同的IMU名称(位置)',
  PowerMeterNameExist: '存在相同的传感器名称(位置)',
  VirtualTSwitchNameUsed: '软接点名称已经存在',
  HasSensorErr: 'IMU下存在传感器，不能修改IMU编码',
  PositionDescribeError: '监测点位置错误，导入失败',
  ProtocolError: '通讯协议错误，导入失败',
  CloudCommunicationStyleError: '通信方式错误，导入失败',
  OwnerPersonError: '产权错误，导入失败',
  StatusError: '状态错误，导入失败',
  SerialPortDataBitError: '校验位错误，导入失败',
  MonitoredObjectTypeError: '被监测对象类型错误，导入失败',
  MonitoringLevelError: '监测等级错误，导入失败',
  IsUsedCalculateResultError: '是否计量结算错误，导入失败',
  PowerClientOrSubstationNotExist: '用电客户或配电房不存在，导入失败',
  MonitoredPointNotSatisfied: '监测点不满足条件，导入失败',
  SensorCreateFailed: '传感器创建失败',
  ImportError: '二次设备导入失败',
  TSwitchNameUsed: '开关名称已存在',
  // SensorPortExist: '软接点导入，通道重复',
  transitionTerminalNameExist: '专变终端名称已存在',
  AcquisitionAndCommunicationModeErr: '采集通讯方式错误',
  UnKnowCode: 'imu模板不存在',
  UnKnowPort: 'imu通道不存在',
  MonitoredPointNotExist: '监测点不存在',
  SubCatalogDevice: '存在下级设备，请先删除下级设备',
  SubCatalogGeneratrix: '存在下级母线，请先删除',
  // bug4115新增错误码
  sensorNameNotExist: '传感器不存在',
  teleindicationPortNameNotExist: '传感器端口不存在',
  SensorPortExist: '传感器端口重复',
  // 传感器相关
  RepeatAtSameLocation: '传感器挂接位置重复',
  MeasuringPointSequenceExist: '测量点序号在对应通信管理机下已存在',

  // 错误码更新
  101001: '名称已经存在',
  101002: 'JSON数据格式不正确',
  101003: '接口参数超出限制',
  101004: '电力户号已存在',
  101005: '简称已存在',
  101006: '客户身份不支持当前操作',
  101007: '调用客户服务查询客户身份错误',
  101008: '用电客户不存在',
  101009: '存在下级设备或监测点，不可删除',
  101010: '用电客户对应用能企业不存在',
  101011: '传感器型号重复',
  101012: '传感器可用数据项无效',
  101013: '创建失败',
  101014: '修改失败',
  101015: '删除失败',
  101016: '查询失败',
  101017: '必填的参数为空',
  101018: 'IMU模板已经使用',
  101019: 'IMU模板不存在',
  101020: '传感器不存在',
  101021: '测量点序号已存在',
  101022: 'Point已经绑定了设备',
  101023: '变压器不存在',
  101024: 'Point不属于同一个站点',
  101025: '存在计量策略',
  101026: '开关名称已使用',
  101027: '对应IMU不存在',
  101028: '对应IMU通道不存在',
  101029: '传感器对应端子不存在',
  101030: '测量点不存在',
  101031: '缺少IMU通道信息',
  101032: '通信管理机已经挂接有传感器',
  101033: '专变终端已经绑定在监测点上',
  101034: 'IMU编码已存在',
  101035: 'IMU的逻辑地址已经存在',
  101036: 'IMU的名称已经存在',
  101037: 'IMU使用的SIM卡号已经存在',
  101038: 'IMU型号不允许修改，因为已经绑定传感器',
  101039: 'IMU的出厂编号已经存在',
  101040: 'IMU的型号不能修改',
  101041: '传感器型号已经使用',
  101042: '任务已下发，需先停用原有配置，才可以修改信息',
  101043: '对象未找到',
  101044: '日期格式不支持',
  101045: '解析Excel出现错误',
  101046: '监测点未与遥信端口进行绑定',
  101047: 'IMU模板名称重复',
  101048: 'IMU模板的编码已经存在',
  101049: '传感器上该端子对应的pointId不存在',
  101050: 'IMU的通道类型错误',
  101051: '配电房下存在监测点',
  101052: '软节点名称已经存在',
  101053: 'DTU通道上的名称已经存在',
  101054: '传感器名称已经存在',
  101055: 'IMU下存在传感器，不能修改IMU编码',
  101056: '专变终端名称已存在',
  101057: '通道类型错误',
  101058: '监测点已绑定传感器，禁止删除',
  101059: '该回路名称已存在',
  101060: '时间重叠',
  101061: '开始时间重叠',
  101062: '起始日期不能早于历史记录日期',
  101063: '第一条策略的起始日期应早于今天',
  101064: '存在下级回路或者变压器',
  101065: '开关上存在对应监测点信息，请先删除相关监测点信息',
  101066: '存在下级回路，请先删除',
  101067: '存在下级监测点，请先删除',
  101068: '存在下级传感器，请先删除',
  101069: '存在下级计量策略，请先删除',
  101070: '存在下级变压器，请先删除',
  101071: '存在下级开关柜，请先删除',
  101072: '存在下级发电机，请先删除',
  101073: '存在下级电容器组，请先删除',
  101074: '当前用电客户下已存在同名的电容器组',
  101075: '当前用电客户下已存在同名的开关柜',
  101076: '一级变压器下只能有一个进线回路',
  101077: '已存在同名的母线',
  101078: '已存在同名的母线',
  101079: '已存在同名的母线',
  101080: '存在下级母线，请先删除',
  101081: '传感器挂接位置重复',
  101082: '逻辑地址和dtuCode不匹配',
  101083: '编码和厂家码、型号码不匹配',
  101084: '字典类型不存在',
  101085: '字典值不存在',
  101086: '设备不存在',
  101087: '数据项不存在',
  101088: '软节点不存在',
  101089: '默认推送规则不能删除',
  101090: '未找到该厂商',
  101091: '传感器模型不存在',
  101092: '无线连接额外属性不存在',
  101093: '厂商信息不存在',
  101094: '传感器3761详情信息不存在',
  101095: '传感器数据项不存在',
  101096: 'DTU串口通道信息不存在',
  101097: 'DTU_TCP通道信息不存在',
  101098: 'DTU_UDP通道信息不存在',
  101099: 'DTU通道模板信息不存在',
  101100: '协议类型数据不存在',
  101101: '通道协议不存在',
  101102: '模型不存在',
  101103: '厂商名称已存在',
  101104: 'dtu376.1下发详情不存在',
  101105: 'DTU_网口通道信息不存在',
  101106: 'DTU模型协议不存在',
  101107: '传感器模型通道信息不存在',
  101108: '传感器模型协议信息不存在',
  101109: 'point点对应信息不存在',
  101110: '端口不可用',
  101111: '厂商类型不存在',
  101112: '组信息不存在',
  101113: 'nvr信息不存在',
  101114: '摄像头信息不存在',
  101115: '站名称已在使用',
  101116: '反相连接信息不存在',
  101117: '通信地址已存在',
  100001: '存在为空的必填字段',
  100002: '非法的用户账号',
  100003: '禁止访问',
  100004: '签名错误',
  100005: '本地时间与服务端时间相差大于一分钟',
  100006: '超出SLA限制',
  100007: '开放接口系统内部错误',
  100008: '没有可调用的后端业务服务',
  100009: '服务调用失败',
  100010: '数据库操作异常',
  100011: 'redis操作异常',
  100012: '分页参数异常',
  100013: '内部错误',
  100014: '枚举值出错',
  101118: '任务已下发，需先停用原有配置，才可以删除信息',
  522004: '设备存在计量点',
  '000022': '企业下边存在人员，不允许删除',
  '000019': '已经存在客户名称',
  '000020': '已经存在客户简称',
  '000021': '企业下边存在电站，不允许删除',

  // 100001: '存在为空的必填字段',
  // 100005: '本地时间与服务端时间相差大于一分钟',
  // 100009: '服务调用失败',
  // 101001: '名称重复',
  // 101002: 'JSON数据格式不正确',
  // 101003: '接口参数超出限制',
  // 101004: '电力户号已存在',
  // 101005: '简称已存在',
  // 101006: '客户身份不支持当前操作',
  // 101007: '调用客户服务查询客户身份错误',
  // 101008: '用电客户不存在',
  // 101009: '存在下级设备或监测点，不可删除',
  // 101010: '用电客户对应用能企业不存在',
  // 101011: '传感器型号重复',
  // 101012: '传感器可用数据项无效',
  // 101013: '创建失败',
  // 101014: '修改失败',
  // 101015: '删除失败',
  // 101016: '查询失败',
  // 101017: '必填的参数为空',
  // 101018: 'IMU模板已经使用',
  // 101019: 'IMU模板不存在',
  // 101020: '传感器不存在',
  // 101021: '测量点序号已存在',
  // 101022: '测量点已经绑定了设备',
  // 101023: '变压器不存在',
  // 101024: 'PointId不属于同一个站点',
  // 101025: '存在计量策略',
  // 101026: '开关名称已使用',
  // 101027: '对应IMU不存在',
  // 101028: '对应IMU通道不存在',
  // 101029: '传感器对应通道不存在',
  // 101030: '测量点不存在',
  // 101031: '缺少IMU通道信息',
  // 101032: '通信管理机已经挂接有传感器',
  // 101033: '专变终端已经绑定在监测点上',
  // 101034: 'IMU编码已存在',
  // 101035: 'IMU设备编码已存在',
  // 101036: 'IMU的名称已经存在',
  // 101037: 'IMU使用的SIM卡号已经存在',
  // 101038: 'IMU型号不允许修改，因为已经绑定传感器',
  // 101039: 'IMU的出厂编号已经存在',
  // 101040: 'IMU的型号不能修改',
  // 101041: '传感器型号已经使用',
  // 101042: '任务已下发，需先停用原有配置，才可以修改信息',
  // 101043: '对象未找到',
  // 101044: '日期格式不支持',
  // 101045: '解析Excel出现错误',
  // 101046: '监测点未与遥信端口进行绑定',
  // 101047: 'IMU型号重复',
  // 101048: 'IMU模板的编码已经存在',
  // 101049: '传感器上该端子对应的pointId不存在',
  // 101050: 'IMU的通道类型错误',
  // 101051: '配电房下监测点',
  // 101052: '软节点名称已经存在',
  // 101053: '配电房下存在监测点',
  // 101054: '传感器名称已经存在',
  // 101055: 'IMU下存在传感器，不能修改IMU编码',
  // 101056: '专变终端名称已存在',
  // 101057: '通道类型错误',
  // 101058: '监测点已绑定传感器，禁止删除',
  // 101059: '该回路名称已存在',
  // 101060: '时间重叠',
  // 101061: '开始时间重叠',
  // 101062: '起始日期不能早于历史记录日期',
  // 101063: '第一条策略的起始日期应早于今天',
  // 101064: '存在下级回路或者变压器',
  // 101065: '开关上存在对应监测点信息，请先删除相关监测点信息',
  // 101066: '存在下级回路，请先删除',
  // 101067: '存在下级监测点，请先删除',
  // 101068: '存在下级传感器，请先删除',
  // 101069: '存在下级计量策略，请先删除',
  // 101070: '存在下级变压器，请先删除',
  // 101071: '存在下级开关柜，请先删除',
  // 101072: '存在下级发电机，请先删除',
  // 101073: '存在下级电容器组，请先删除',
  // 101074: '当前用电客户下已存在同名的电容器组',
  // 101075: '当前用电客户下已存在同名的开关柜',
  // 101076: '一级变压器下只能有一个进线回路',
  // 101077: '已存在同名的母线',
  // 101078: '上下级开关回路的名称不能重复',
  // 101079: '存在下级设备，请先删除下级设备',
  // 101080: '存在下级母线，请先删除',
  // 101081: '传感器挂接位置重复',
  // 101092: '设备名称重复',
  // 101093: '设备与传感器不匹配',

  // 客户管理相关
  CompanyNameExist: '该客户名称已经存在',
  RequestInfoAlreadyExists: '该企业用电信息已存在！',
  CustomerNotExist: '客户不存在',
  ParameterEmpty: '参数为空',
  EmployeeExist: '该客户下存在员工',
  ServiceProviderNotFound: '服务商不存在',
  InvalidParameter: '缺少参数',
  PowerClientExist: '该客户下存在用电客户',
  CompanyShortNameExist: '企业简称已存在',
  AccountBindOtherEmployee: '账号已经绑定其他员工',
  CompanyMustBeEnergyEnterprise: '该客户下有用电客户，客户类型需包含用能企业',

  // 计量策略新增英文code
  RepeatMeasurement: '生效时间已存在计量策略',
  ExistedMeasurementPoint: '当前设备已存在计量点',
};

export default function mapCodeToMsg(code, message) {
  if (/[\u4e00-\u9fa5]/.test(message)) {
    return message;
  }
  return codeHash[code] || '';
}
